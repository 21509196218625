

.nav-tabs {
  border: none;
  a.nav-link {
    font-size: 0.75rem;
    color: $black;
    padding: 17px 0;
    border: none;
    background-color: transparent;
    margin-right: 40px;
    @include media-breakpoint-down(lg){
      margin-right: 0;
      font-size: 0.7rem;
      padding: 13px 0;
    }
    @include media-breakpoint-down(md){
      font-size: 0.65rem;
    }
    @include media-breakpoint-down(md){
      text-align: center;
      border-bottom: $primary-border;
    }
    &.active {
      font-weight: 600;
      color: inherit;
      background-color: transparent;
      //box-shadow: inset 0 -2px 0 0 rgba(14,39,89,1);
      border-bottom: 2px solid $blue;
    }
  }
}

.nav-tabs.nav-tabs-accent {
  width: 100%;
  border-radius: 2px;
  height: 46px;
  .nav-link {
    flex: 1;
    height: 100%;
    font-size: 0.75rem;
    border-radius: 0;
    border: $primary-border;
    &.active {
      background-color: $accent;
      border-color: $accent;
      color: $white;
    }
  }
  &#add-payment-type-tab {
    height: unset;

    button {
      height: unset;
      padding: 12px 15px 14px 15px;
    }

  }
}

.tabs-swiper {
  nav {
    flex-wrap: nowrap;
    align-items: stretch;
    height: 55px;
    @include media-breakpoint-down(lg){
      height: 75px;
    }
    @include media-breakpoint-down(sm){
      height: 50px;
      padding: 0 45px 0 15px;
    }
    .swiper-slide.nav-link {
      text-align: center;
      height: 100%;
      @include media-breakpoint-up(lg){
        width: fit-content!important;
        white-space: nowrap;
      }

      @include media-breakpoint-down(sm){
        white-space: nowrap;
        width: fit-content!important;
      }
    }
  }
}

