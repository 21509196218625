.text-with-read-more {
  p:first-of-type {
    display: inline;
  }

  .read-more-input {
    display: none;
    & ~ p {
      display: none;
    }
  }

  label.read-more-label {
    font-size: 0.8rem;
    color: $blue;
    line-height: 22px;
    font-weight: 400;
    text-decoration: underline;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }

  input:checked {
    & ~ label {
      display: none;
    }
    & ~ p {
      display: block;
    }
  }
}
