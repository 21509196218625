.landing-swiper {
  height: 300px;
  @include media-breakpoint-down(xl){
    height: 250px;
  }
  @include media-breakpoint-down(lg){
    height: 200px;
  }
  .swiper-slide {
    div {
      width: 100%;
      height: 100%;
      background: url("../../assets/img/landing-banner.svg") no-repeat center;
      background-size: cover;
      @include media-breakpoint-down(lg){
        background-position: 40%;
      }
      @include media-breakpoint-down(md){
        background-position: 80%;
      }


      h1 {
        font-size: 2.1rem;
        font-weight: 900;
        color: $white;
        @include media-breakpoint-down(lg){
          font-size: 1.5rem;
        }
        @include media-breakpoint-down(md){
          max-width: 65%;

        }
        @include media-breakpoint-down(sm){
          max-width: 50%;
          font-size: 1.2rem;
        }
      }
      h2 {
        font-family: 'Montserrat Alternates', sans-serif;
        color: $white;
        margin-left: 50px;
        letter-spacing: 2px;
        text-transform: uppercase;
        @include media-breakpoint-up(xl){
          font-size: 3rem;
          margin-left: 120px;
        }
        @include media-breakpoint-up(lg){
          font-size: 2.8rem;
          margin-left: 100px;
        }
        @include media-breakpoint-down(md){
          margin-left: 0;
          margin-top: 10px;
        }
        @include media-breakpoint-down(sm){
          max-width: 50%;
          font-size: 1rem;
        }

      }
    }

  }
  .swiper-pagination-bullet {
    height: 8px;
    width: 8px;
    border: 1px solid $white;
    background-color: transparent;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background-color: $white;
    }
  }
}