header {
  background-color: $white;
}
nav {
  .logo {
    img {
      @include media-breakpoint-down(xl){
        width: 220px;
      }
      @include small-mobile {
        width: 180px;
      }
    }

  }
  .navbar-collapse {
    @include media-breakpoint-down(lg) {
      background-color: $blue;
      position: fixed;
      width: 50vw;
      top: 0;
      padding-top: 120px;
      left: auto;
      overflow-y: auto;
      height: 100vh;
      right: -120%;
      transition: .3s;
      z-index: 17;

      &.show {
        right: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100vw;
    }

    & > a {
      margin-left: 50px;
      font-size: 0.7rem;
      text-transform: uppercase;
      &:hover {
        color: $accent;
        svg {
            * {
              fill: $accent;
            }
        }
      }
      @include media-breakpoint-down(xl){
        margin-left: 30px;
      }
      @include media-breakpoint-down(lg){
        margin-left: 0;
        margin-bottom: 40px;
        color: $white;
        svg {
          * {
            fill: $white;
          }
        }
      }
    }
  }
}