.wrapper-block {
  padding: 20px 25px 25px 25px;
  border: $primary-border;
  background-color: $white;
  @include small-mobile {
    margin-left: -15px;
    margin-right: -15px;
    padding: 20px 15px 30px 15px;
    border: none;

  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.35em;
    margin-bottom: 25px;
  }

  p {
    font-size: 0.7rem;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    strong {
      font-weight: 600;
    }
  }

  &.ad-info-block {
    @include media-breakpoint-down(lg){
      padding: 20px 15px 25px 15px;
    }
    @include media-breakpoint-down(md){
      padding: 20px 25px 25px 25px;
    }
    & > .d-flex {
      margin-bottom: 10px;
    }

    h6, h5 {
      font-size: 0.75rem;
      line-height: 1.66em;
      font-weight: 400;
      @include media-breakpoint-down(lg){
        font-size: 0.7rem;
      }
      @include media-breakpoint-down(md){
        font-size: 0.75rem;
      }
      @include media-breakpoint-down(sm){
        font-size: 0.7rem;
      }
    }
    .totals {
      margin-bottom: 20px;
      h6, h5 {
        font-weight: 700;
      }
    }
  }
  .info {
    background-color: rgba(255,122,0,0.3);
    padding: 18px 20px 15px 20px;
    border-radius: 2px;
    @include media-breakpoint-down(sm){
      padding: 15px 15px 12px 15px;
    }
    p {
      font-size: 0.7rem;
    }
    h6 {
      font-size: 0.7rem;
      font-weight: 600;
      margin-bottom: 11px;
    }
    &.info-blue {
      background-color: rgba(14,39,89,0.2);
    }
  }

  &.add-ad-by-phone-block {
    background-color: rgba(14,39,89,0.05);
    border-color: transparent;

  }
  .select-wrap {
    width: 50%;
    margin-bottom: 30px;
    @include media-breakpoint-down(lg){
      width: 100%;
    }
    @include media-breakpoint-down(md){
      width: 50%;
    }
    @include media-breakpoint-down(sm){
      width: 100%;
    }
  }
  .ad-input-wrap {
    input {
      border: 2px solid $accent;
      border-radius: 2px;
    }
  }
}

.input-wrap.radio-input-wrap {
  display: flex;
  gap: 15px 0;
  flex-wrap: wrap;
  margin-bottom: 30px;
  & > label {
    width: 100%;
    display: block;
    margin-bottom: 0;
  }
  .custom-radio {
    width: 25%;
    padding-right: 20px;
    @include media-breakpoint-down(xl){
      width: 33.33%;
    }
    @include media-breakpoint-down(lg){
      width: 50%;
    }
    @include media-breakpoint-down(md){
      width: 33.333%;
    }
    @include media-breakpoint-down(sm){
      width: 50%;
    }
  }
}