.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;

  @include media-breakpoint-down(sm){
    margin: 0 auto
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.666em;
    font-family: "PlayfairDisplay";
    transition: $transition-all;
  }
  p {
    opacity: 0.6;
    font-size: 0.75rem;
    margin-bottom: 10px;

    &.price {
      font-size: 0.8rem;
      color: $blue;
      opacity: 1;
      margin-bottom: 30px;
      @include media-breakpoint-down(sm){
        margin-bottom: 15px;
      }
    }
  }
  .button {
    margin-top: auto;
  }

  &.product-card-2, &.product-card-3 {
    .img-wrap {
      padding-bottom: 4px;
      padding-right: 4px;
      height: 234px;
      img {
        object-position: top;
      }
      @include media-breakpoint-down(sm){
        height: 280px;
      }
      @include small-mobile{
        height: 210px;
      }
      @include xsmall-mobile{
        height: 180px;
      }
      &:after {
        top: 4px;
        left: 4px;
      }
    }
    h4 {
      font-size: 0.8rem;
      line-height: 1.5em;
    }
    p {
      margin-bottom: 20px;
      font-size: 0.65rem;
    }
    h6 {
      color: $blue;
      line-height: 1.3333em;
      font-weight: 400;
      font-size: 0.75rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }


  &.product-card-3 {
    @include media-breakpoint-down(xl){
      .img-wrap {
        height: 270px;
      }
    }
    @include media-breakpoint-down(md) {
      .img-wrap {
        height: 320px;
      }
    }
    @include media-breakpoint-down(sm) {
      .img-wrap {
        height: 320px;
      }
    }
    @include md-mobile {
      .img-wrap {
        height: 260px;
      }
    }
    @include small-mobile {
      .img-wrap {
        height: 220px;
      }
    }
    @include xsmall-mobile {
      .img-wrap {
        height: 180px;
      }
    }

    p {
      margin-bottom: 10px;
      font-size: 0.8rem;
      line-height: 1.5em;
      opacity: 1;

    }
  }

  &.product-card-combo {
    max-width: 530px;
    .card-wrap {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding: 40px;
      height: 380px;
      background-color: rgba(14,39,89,0.05);
      margin-bottom: 20px;
      @include media-breakpoint-down(xl){
        height: 310px;
      }
      @include media-breakpoint-down(lg){
        padding: 30px;
        height: 280px;
        margin-bottom: 10px;
      }
      @include media-breakpoint-down(md){
        height: 200px;
        padding: 20px;
      }
      @include media-breakpoint-down(sm){
        height: 300px;
        justify-content: center;
        gap: 0 40px;
      }
      @include small-mobile {
        height: 260px;
      }
      @include xsmall-mobile {
        height: 230px;
      }

      & > img {
        max-width: 90px;
        z-index: 5;
        @extend .absolute-center-absolute;
        @include media-breakpoint-down(lg){
          max-width: 60px;
        }
        @include media-breakpoint-down(md){
          max-width: 50px;
        }
        @include media-breakpoint-down(sm){
          max-width: 90px;
        }
        @include small-mobile {
          max-width: 70px;
        }
        @include xsmall-mobile {
          max-width: 50px;
        }
      }

      &:after{
        content: none;
      }
    }
    .img-wrap {
      height: fit-content;
      max-width: 186px;
      margin-bottom: 0;
      padding-right: 6px;
      padding-bottom: 6px;
      &:after {
        top: 6px;
        left: 6px;
      }
      @include media-breakpoint-down(xl){
        max-width: 150px;
      }
      @include media-breakpoint-down(lg){
        max-width: 120px;
      }
      @include media-breakpoint-down(md){
        max-width: 90px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 165px;
        padding-right: 10px;
        padding-bottom: 10px;
        &:after {
          top: 10px;
          left: 10px;
        }
      }
      @include xsmall-mobile {
        padding-right: 5px;
        padding-bottom: 5px;
        &:after {
          top: 5px;
          left: 5px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:nth-of-type(1){
        margin-bottom: auto;
      }
      &:nth-of-type(2) {
        margin-top: auto;
      }
    }
  }
  &:hover {
    h4 {
      color: $accent;
    }
  }
}