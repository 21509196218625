.img-wrap {
  position: relative;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  @include media-breakpoint-down(md){
    margin-bottom: 5px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    left: 10px;
    bottom: 0;
    background-color: rgba(14,39,89,0.1);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.img-wrap-xs {
    padding-right: 3px;
    padding-bottom: 3px;
    &:after {
      top: 3px;
      left: 3px;
    }
  }
}

