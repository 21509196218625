.product-select-radio {
  @include media-breakpoint-up(sm){
    min-width: 33.3333%;
  }
  input {
    display: none;
  }
  label.product-radio-label {
    display: flex;
    height: 100%;
    flex-direction: column;
    text-align: center;
    padding: 20px 20px 25px 20px;
    background-color: white;
    border: $primary-border;
    cursor: pointer;
    @include media-breakpoint-down(sm){
      padding: 15px;
      display: grid;
      grid-template-columns: 120px 1fr;
    }
    @include xsmall-mobile {
      grid-template-columns: 100px 1fr;
    }
    h6 {
      font-size: 0.8rem;
      line-height: $lh-wider;
      font-family: "Open Sans";
      margin-bottom: 20px;
      @include media-breakpoint-down(xl){
        white-space: nowrap;
        font-size: 0.7rem;
      }
      @include media-breakpoint-down(sm){
        margin-bottom: 10px;
        grid-column: 2/3;
        font-size: 0.9rem;
      }
      @include small-mobile {
        font-size: 0.8rem;
      }
      @include xsmall-mobile {
        font-size: 0.7rem;
      }
    }
    .price {
      font-size: 0.8rem;
      font-weight: 700;
      margin-bottom: 5px;
      margin-top: auto;
      @include media-breakpoint-down(sm){
        margin-top: unset;
      }
      @include xsmall-mobile {
        font-size: 0.7rem;
      }
    }
    p:not(.price){
      font-size: 0.7rem;
    }
    img {
      width: 100%;
      max-height: 140px;
      //margin-bottom: 20px;
      margin-top: auto;
      @include media-breakpoint-down(sm){
        max-height: 120px;
        margin-bottom: 10px;
      }
    }
    &:hover {
      border-color: rgba(14,39,89,0.5);
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.05);
    }
    &:active, &:focus {
      border-color: rgba(14,39,89,1);
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.05);
    }
  }
  input:checked ~ label {
    border-color: $blue!important;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.05);
  }

  .img-wrap {
    width: 100px;
    height: 140px;
    margin: 0 auto;
    padding-bottom: 3px;
    padding-right: 3px;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm){
      width: 90px;
      height: 120px;
      grid-row: 1/4;
      margin-bottom: 0px;
    }
    @include small-mobile {
      margin: 0;
      margin-right: 10px;
    }
    &:after {
      top: 3px;
      left: 3px;
    }
  }
}

.duration-radio {
  padding: 20px;
  background-color: $white;
  border: $primary-border;
  margin-bottom: 5px;
  position: relative;
  * {
    white-space: nowrap;
  }
  @include media-breakpoint-down(xl){
    padding: 15px;
  }
  @include media-breakpoint-down(lg){
    padding: 20px;
  }
  @include media-breakpoint-down(md){
    padding: 15px;
  }
  @include small-mobile {
    padding-top: 40px;
  }

  input {
    display: none;
  }

  label.duration-radio-label {
    @include media-breakpoint-down(md){

      * {
        white-space: nowrap;
      }
    }
    @include media-breakpoint-down(sm){
      flex-direction: column;
      gap: 10px;

      .subscription-info {
        justify-content: center;
      }
    }
  }

  input:checked ~ label {
    border-color: $blue;
  }

  .price {
    font-size: 1rem;
    font-weight: 700;
    padding: 0 20px;
    white-space: nowrap;
    @include media-breakpoint-down(xl) {
      padding: 0 10px 0 20px;
      font-size: 0.8rem;
    }
  }
  .button {
    height: 46px;
    font-size: 0.75rem;
    white-space: nowrap;
    @include media-breakpoint-down(xl){
      font-size: 0.65rem;
      height: 40px;
    }
    @include small-mobile {
      width: 100%;
    }
  }
}

.custom-radio {
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    p {
      font-size: 0.7rem;
      margin-left: 15px;
      margin-bottom: 0;
      &:nth-of-type(2){
        font-size: 0.6rem;
        line-height: 1.666em
      }
      @include small-mobile {
        font-size: 0.65rem;
        margin-left: 10px;
      }
    }
    .checkmark {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border: $primary-border;
      position: relative;
      flex-shrink: 0;
      @include media-breakpoint-down(sm){
        height: 24px;
        width: 24px;
      }
      &:after {
        content: none;
        position: absolute;

      }
    }
  }
  input:checked ~ label .checkmark {
    &:after {
      content: "";
      @extend .absolute-center-absolute;
      background-color: $accent;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      @include media-breakpoint-down(sm){
        width: 14px;
        height: 14px;
      }

    }
  }
}

.bank-radio {
  input {
    display: none;
  }
  label {
    height: 90px;
    padding: 30px;
    border: $primary-border;
    border-radius: 2px;
    @extend .absolute-center;
    @include media-breakpoint-down(lg){
      padding: 20px;
      height: 70px;
    }

    img{
      object-fit: contain;
      max-width: 180px;
    }
  }
  input:checked ~ label {
    border-color: $blue;
    -webkit-box-shadow: 0px 0px 0px 1px $blue;
    box-shadow: 0px 0px 0px 1px $blue;;
  }
}

