p, a, span, label {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.333em;
}

* {
  color: $black;
}

h1, h2, h3, h4, h5, h6 {
  //font-family: "CabinetGrotesk";
  line-height: 1.333em;
  font-weight: 500;

  strong {
    font-weight: 700;
  }
}

h1 {
  font-family: "PlayfairDisplay";
}

.playfair {
  font-family: "PlayfairDisplay";
}

h2 {
  font-family: "PlayfairDisplay";
  font-size: 1.8rem;
  line-height: 1.25em;
  @include media-breakpoint-down(sm){
    font-size: 1.4rem;
  }
}

h3 {
  font-size: 1.2rem;
  line-height: 1.458em;
}

p {
  font-size: 0.8rem;
  line-height: 1.375em;
  @include media-breakpoint-down(sm) {
    font-size: 0.7rem;
  }
}

h6.section-label {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5625em;
}

label {
  font-weight: 600;
  font-size: 0.7rem;
}