.custom-checkbox {
  cursor: pointer;
  input {
    display: none;
  }
  label {
    display: flex;
    font-size: 0.7rem;
    line-height: 28px;
    font-weight: 400;

    p {
      font-size: 0.7rem;
      padding-top: 3px;
    }

    .checkmark {
      position: relative;
      height: 28px;
      width: 28px;
      border: $primary-border;
      border-radius: 2px;
      background-color: $white;
      margin-right: 15px;
      flex-shrink: 0;
      cursor: pointer;
      &:after {
        content: none;
        position: absolute;
        background-image: url("../../assets/img/checkmark.svg");
        background-size: contain;
        height: 13px;
        width: 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  input:checked ~ label .checkmark:after {
    content: "";
  }
}

.subscribe-section {
  .custom-checkbox .checkmark {
    border: 1px solid $accent;
  }
}

.manual-input-option {
  display: none;
  & ~ label {
    display: flex;
    font-size: 0.7rem;
    line-height: 28px;
      .checkmark {
        position: relative;
        height: 28px;
        width: 28px;
        border: $primary-border;
        border-radius: 2px;
        background-color: $white;
        margin-right: 15px;
        flex-shrink: 0;
        &:after {
          content: none;
          position: absolute;
          background-image: url("../../assets/img/checkmark.svg");
          background-size: contain;
          height: 13px;
          width: 15px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    & ~ .input-wrap {
      display: none;
      &:nth-of-type(2) {
        display: block;
      }
    }
  }

  &:checked ~ label {
    .checkmark:after {
      content: "";
    }
    & ~ .input-wrap {
      display: block;
      &:nth-of-type(2) {
        display: none;
      }
    }
  }
}




