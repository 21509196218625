

.product-section {
  &.product-section-2 {
    .row:nth-of-type(2) {
      & > div:nth-of-type(2){
        .product-card {
          @include media-breakpoint-up(md){
            margin-left: auto;
          }
        }
      }
    }
  }
  &.product-section-3 {
    .product-card {
      max-width: 320px;
      @include media-breakpoint-down(sm){
        max-width: 250px;
      }

      .img-wrap {
        height: 430px;
        @include media-breakpoint-down(xl){
          height: 400px;
        }
        @include media-breakpoint-down(lg){
          height: 300px;
        }
        @include media-breakpoint-down(md){
          height: 340px;
        }
      }

    }
  }

  &.product-section-4 {
    padding-bottom: 80px;
    @include media-breakpoint-down(sm){
      padding-bottom: 40px;
    }
    .product-card {
      max-width: 285px;
      @include media-breakpoint-down(sm){
        max-width: 250px;
      }
      .img-wrap {
        height: 386px;
        @include media-breakpoint-down(xl){
          height: 290px;
        }
        @include media-breakpoint-down(lg){
          height: 386px;
        }
        @include media-breakpoint-down(md){
          height: 320px;
        }
        @include media-breakpoint-down(sm){
          height: 340px;
        }
      }
    }
  }
}