.accordion.faq-accordion {
  .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-radius: 0;
  }
  button {
    padding: 25px 5px 20px 0;
    align-items: center;
    h3 {
      font-family: "PlayfairDisplay";
      @include media-breakpoint-down(sm){
       font-size: 1rem;
      }
    }
    img {
      transition: $transition-all;
      transform: rotate(270deg);
    }
    &.collapsed {
      img {
        transform: rotate(90deg);
      }
    }
  }
  .accordion-collapse {
    p {
      font-size: 0.8rem;
      line-height: 1.375em;
      margin-bottom: 25px;
      padding-right: 20px;
    }
  }
}