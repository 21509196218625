.order-page {
  h3 {
    @include media-breakpoint-down(md){
      font-size: 1rem;
    }
    @include media-breakpoint-down(sm){
      padding-right: 20px;
    }
  }
  .wrapper-block {
    padding: 20px 20px 30px 25px;
  }
}
.order-block {
  position: relative;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-right: 30px;
  border-bottom: $primary-border;
  @include media-breakpoint-down(sm){
    padding-right: 10px;
  }
  .wrap-mobile {
    @include media-breakpoint-down(sm){
      margin-top: 30px;
    }
  }
  .img-wrap {
    width: 100px;
    height: 140px;
    grid-row: 1 / 3;
    @include media-breakpoint-down(sm){
      grid-row: 1 / 2;
      width: 90px;
      height: 120px;
    }
  }
  .price {
      font-size: 0.8rem;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 35px;
      white-space: nowrap;
      @include media-breakpoint-down(sm){
        font-size: 14px;
      }
  }
  .delete-order {
    position: absolute;
    top: 42px;
    right: 0;
  }
  .input-label {
    span {
      font-weight: 400;
    }
  }
  .order-start-select-wrap {
    margin-bottom: 20px;
    grid-row: 2/3;
    @include media-breakpoint-down(lg){
      gap: 10px;
    }
     a {
      white-space: nowrap;
    }
    a {
        font-size: 0.75rem;
        color: $blue;
        align-items: center;
      img {
        width: 16px;
      }
    }
  }
  .display-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr;

    .order-block-title {
      margin-left: 40px;
      @include media-breakpoint-down(xl){
        margin-left: 20px;
      }
      @include media-breakpoint-down(sm){
        margin-left: 10px;
      }
    }

    .subscription-selections {
      grid-column: 2/3;
      margin-left: 40px;
      @include media-breakpoint-down(xl){
        margin-left: 20px;
      }
      @include media-breakpoint-down(sm){
        grid-column: 1/3;
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}