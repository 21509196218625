nav {
  .navbar-toggler {
    z-index: 18;
    padding: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $white;
    position: relative;
    &:focus, &:active, &:focus-within, &:focus-visible {
      box-shadow: $primary-shadow;
    }
  }

  button.navbar-toggler label {
    position: absolute;
    height: 8px;
    width: 20px;
    z-index: 12;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      position: absolute;
      width: 100%;
      height: 1px;
      display: block;
      transition: .5s;
      background-color: $black;
    }

    span:first-child {
      top: 0;
    }

    span:last-child {
      bottom: 0;
      width: 75%;
    }
  }

  button.navbar-toggler:not(.collapsed) {
    background-color: $accent;

    label {
      span:first-child {
        transform: rotate(405deg);
        top: 4px
      }

      span:last-child {
        transform: rotate(-405deg);
        width: 100%;
        bottom: 3px;
      }
    }
  }

}
