section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.hero-section {
  padding-top: 40px;
  padding-bottom: 30px;
}

.hero-index-section {
  @include media-breakpoint-down(md) {
    padding-top: 0;
    .container {
      max-width: unset;
      padding: 0;
    }
  }
}

.auth-page, .user-page {
  padding: 40px 0 80px 0;
}

.ad-page {
  padding: 40px 0 30px 0;
  @include media-breakpoint-down(sm){
    padding: 40px 0 0 0;
  }
}