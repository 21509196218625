/* line 135, src/assets/scss/_variables.scss */
.text-grey-sheer-light {
  color: rgba(51, 51, 51, 0.03); }

/* line 138, src/assets/scss/_variables.scss */
.bg-grey-sheer-light {
  background-color: rgba(51, 51, 51, 0.03); }

/* line 135, src/assets/scss/_variables.scss */
.text-lightest-sheer {
  color: rgba(0, 0, 0, 0.05); }

/* line 138, src/assets/scss/_variables.scss */
.bg-lightest-sheer {
  background-color: rgba(0, 0, 0, 0.05); }

/* line 135, src/assets/scss/_variables.scss */
.text-primary {
  color: #F8D121; }

/* line 138, src/assets/scss/_variables.scss */
.bg-primary {
  background-color: #F8D121; }

/* line 135, src/assets/scss/_variables.scss */
.text-secondary {
  color: #F4B000; }

/* line 138, src/assets/scss/_variables.scss */
.bg-secondary {
  background-color: #F4B000; }

/* line 135, src/assets/scss/_variables.scss */
.text-blue {
  color: #0e2759; }

/* line 138, src/assets/scss/_variables.scss */
.bg-blue {
  background-color: #0e2759; }

/* line 135, src/assets/scss/_variables.scss */
.text-red {
  color: red; }

/* line 138, src/assets/scss/_variables.scss */
.bg-red {
  background-color: red; }

/* line 135, src/assets/scss/_variables.scss */
.text-accent {
  color: #ff7a00; }

/* line 138, src/assets/scss/_variables.scss */
.bg-accent {
  background-color: #ff7a00; }

/* line 135, src/assets/scss/_variables.scss */
.text-blue-sheer {
  color: rgba(47, 60, 163, 0.05); }

/* line 138, src/assets/scss/_variables.scss */
.bg-blue-sheer {
  background-color: rgba(47, 60, 163, 0.05); }

/* line 135, src/assets/scss/_variables.scss */
.text-grey-sheer {
  color: rgba(51, 51, 51, 0.05); }

/* line 138, src/assets/scss/_variables.scss */
.bg-grey-sheer {
  background-color: rgba(51, 51, 51, 0.05); }

/* line 135, src/assets/scss/_variables.scss */
.text-dark-blue {
  color: #040B1A; }

/* line 138, src/assets/scss/_variables.scss */
.bg-dark-blue {
  background-color: #040B1A; }

/* line 135, src/assets/scss/_variables.scss */
.text-light-blue {
  color: #46cbf8; }

/* line 138, src/assets/scss/_variables.scss */
.bg-light-blue {
  background-color: #46cbf8; }

/* line 135, src/assets/scss/_variables.scss */
.text-yellow {
  color: #F7CA00; }

/* line 138, src/assets/scss/_variables.scss */
.bg-yellow {
  background-color: #F7CA00; }

/* line 135, src/assets/scss/_variables.scss */
.text-green {
  color: #17590e; }

/* line 138, src/assets/scss/_variables.scss */
.bg-green {
  background-color: #17590e; }

/* line 135, src/assets/scss/_variables.scss */
.text-dark-green {
  color: #102f0b; }

/* line 138, src/assets/scss/_variables.scss */
.bg-dark-green {
  background-color: #102f0b; }

/* line 135, src/assets/scss/_variables.scss */
.text-light-green {
  color: #30ea18; }

/* line 138, src/assets/scss/_variables.scss */
.bg-light-green {
  background-color: #30ea18; }

/* line 135, src/assets/scss/_variables.scss */
.text-grey {
  color: #757575; }

/* line 138, src/assets/scss/_variables.scss */
.bg-grey {
  background-color: #757575; }

/* line 135, src/assets/scss/_variables.scss */
.text-dark-grey {
  color: #3b3b3b; }

/* line 138, src/assets/scss/_variables.scss */
.bg-dark-grey {
  background-color: #3b3b3b; }

/* line 135, src/assets/scss/_variables.scss */
.text-light-grey {
  color: #fafafa; }

/* line 138, src/assets/scss/_variables.scss */
.bg-light-grey {
  background-color: #fafafa; }

/* line 135, src/assets/scss/_variables.scss */
.text-white {
  color: #ffffff; }

/* line 138, src/assets/scss/_variables.scss */
.bg-white {
  background-color: #ffffff; }

/* line 135, src/assets/scss/_variables.scss */
.text-black {
  color: #000000; }

/* line 138, src/assets/scss/_variables.scss */
.bg-black {
  background-color: #000000; }

/* line 135, src/assets/scss/_variables.scss */
.text-primary-bg-color {
  color: #947777; }

/* line 138, src/assets/scss/_variables.scss */
.bg-primary-bg-color {
  background-color: #947777; }

/* line 135, src/assets/scss/_variables.scss */
.text-secondary-bg-color {
  color: #4c7571; }

/* line 138, src/assets/scss/_variables.scss */
.bg-secondary-bg-color {
  background-color: #4c7571; }

/* line 135, src/assets/scss/_variables.scss */
.text-primary-text-color {
  color: #111010; }

/* line 138, src/assets/scss/_variables.scss */
.bg-primary-text-color {
  background-color: #111010; }

/* line 135, src/assets/scss/_variables.scss */
.text-secondary-text-color {
  color: #ffffff; }

/* line 138, src/assets/scss/_variables.scss */
.bg-secondary-text-color {
  background-color: #ffffff; }

/* line 135, src/assets/scss/_variables.scss */
.text-sheer {
  color: rgba(255, 255, 255, 0.5); }

/* line 138, src/assets/scss/_variables.scss */
.bg-sheer {
  background-color: rgba(255, 255, 255, 0.5); }

/* line 135, src/assets/scss/_variables.scss */
.text-main-text-color {
  color: #000000; }

/* line 138, src/assets/scss/_variables.scss */
.bg-main-text-color {
  background-color: #000000; }

/* line 1, src/assets/scss/_reset.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none; }

/* HTML5 display-role reset for older browsers */
/* line 11, src/assets/scss/_reset.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 14, src/assets/scss/_reset.scss */
body {
  line-height: 1; }

/* line 17, src/assets/scss/_reset.scss */
ol, ul {
  list-style: none; }

/* line 20, src/assets/scss/_reset.scss */
blockquote, q {
  quotes: none; }

/* line 24, src/assets/scss/_reset.scss */
blockquote:before, blockquote:after {
  content: '';
  content: none; }

/* line 29, src/assets/scss/_reset.scss */
q:before, q:after {
  content: '';
  content: none; }

/* line 33, src/assets/scss/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../assets/fonts/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../assets/fonts/PlayfairDisplay-Black.ttf") format("truetype");
  font-weight: 800; }

@font-face {
  font-family: "CabinetGrotesk";
  src: url("../../assets/fonts/CabinetGrotesk-Regular.otf") format("opentype");
  font-weight: 400; }

@font-face {
  font-family: "CabinetGrotesk";
  src: url("../../assets/fonts/CabinetGrotesk-Medium.otf") format("opentype");
  font-weight: 500; }

@font-face {
  font-family: "MontserratAlternates";
  src: url("../../assets/fonts/MontserratAlternates-Italic.ttf") format("truetype");
  font-style: italic; }

/* line 1, src/assets/scss/_global.scss */
body {
  background-color: #f3f4f7; }
  /* line 4, src/assets/scss/_global.scss */
  body.no-scroll {
    overflow: hidden;
    height: 100vh; }
  /* line 10, src/assets/scss/_global.scss */
  body.filter .black-filter {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 13; }

/* line 22, src/assets/scss/_global.scss */
html {
  font-size: 20px;
  scroll-behavior: smooth; }

/* line 27, src/assets/scss/_global.scss */
* {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif; }

/* line 32, src/assets/scss/_global.scss */
img {
  height: auto; }

/* line 36, src/assets/scss/_global.scss */
a, button {
  transition: .3s; }

/* line 41, src/assets/scss/_global.scss */
a {
  color: inherit; }

/* line 47, src/assets/scss/_global.scss */
[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: initial; }

/* line 51, src/assets/scss/_global.scss */
.transition {
  transition: .3s; }

/* line 1, src/assets/scss/_utilities.scss */
.op-60 {
  opacity: 0.6; }

/* line 5, src/assets/scss/_utilities.scss */
.op-40 {
  opacity: 0.4; }

/* line 9, src/assets/scss/_utilities.scss */
.absolute-center, .button, .bank-radio label {
  display: flex;
  justify-content: center;
  align-items: center; }

/* line 15, src/assets/scss/_utilities.scss */
.absolute-center-absolute, .product-card.product-card-combo .card-wrap > img, .custom-radio input:checked ~ label .checkmark:after, .custom-radio select.select-custom:checked ~ label .checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@media (max-width: 767.98px) {
  /* line 22, src/assets/scss/_utilities.scss */
  .mobile-border-bottom {
    border-bottom: 1px solid rgba(14, 39, 89, 0.2); } }

/* line 28, src/assets/scss/_utilities.scss */
.w-fit {
  width: fit-content; }

/* line 32, src/assets/scss/_utilities.scss */
.blured {
  position: relative; }
  /* line 34, src/assets/scss/_utilities.scss */
  .blured:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.6);
    backdrop-filter: blur(3px); }

/* line 47, src/assets/scss/_utilities.scss */
.font-weight-semi {
  font-weight: 600; }

@media (min-width: 576px) {
  /* line 51, src/assets/scss/_utilities.scss */
  .mobile-visible-only {
    display: none !important; } }

/* line 57, src/assets/scss/_utilities.scss */
.hidden {
  display: none; }

/* line 61, src/assets/scss/_utilities.scss */
.card-border-radius {
  border-radius: 20px; }

/* line 65, src/assets/scss/_utilities.scss */
.border-bottom {
  border-bottom: 1px solid rgba(14, 39, 89, 0.2); }

@media (max-width: 575.98px) {
  /* line 69, src/assets/scss/_utilities.scss */
  .row-wrap-sm {
    margin-right: -10px;
    margin-left: -10px; } }

/* line 2, src/assets/scss/components/_buttons.scss */
button {
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0; }
  /* line 9, src/assets/scss/components/_buttons.scss */
  button img {
    object-position: center; }
  /* line 12, src/assets/scss/components/_buttons.scss */
  button:focus-visible, button:focus-within, button:active, button:focus {
    outline: none; }

/* line 17, src/assets/scss/components/_buttons.scss */
.button {
  height: 54px;
  color: #0e2759;
  background-color: #ffffff;
  font-size: 0.8rem;
  letter-spacing: 0;
  line-height: 1.333em;
  border: 1px solid rgba(14, 39, 89, 0.2);
  border-radius: 2px;
  transition: all 0.2s ease-in-out; }
  /* line 28, src/assets/scss/components/_buttons.scss */
  .button:hover {
    border-color: #0e2759;
    color: #ffffff;
    background-color: #0e2759; }
  /* line 33, src/assets/scss/components/_buttons.scss */
  .button.blue-button {
    border-color: #0e2759;
    color: #ffffff;
    background-color: #0e2759; }
    /* line 37, src/assets/scss/components/_buttons.scss */
    .button.blue-button:hover {
      color: #0e2759;
      background-color: transparent;
      border: 1px solid rgba(14, 39, 89, 0.2); }
  /* line 44, src/assets/scss/components/_buttons.scss */
  .button.grey-ish-button {
    background-color: rgba(14, 39, 89, 0.1);
    border-color: transparent;
    color: #000000; }
    /* line 48, src/assets/scss/components/_buttons.scss */
    .button.grey-ish-button:hover {
      color: #0e2759;
      border-color: rgba(14, 39, 89, 0.2); }
  /* line 53, src/assets/scss/components/_buttons.scss */
  .button.grey-button {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: transparent;
    color: #000000; }
    /* line 57, src/assets/scss/components/_buttons.scss */
    .button.grey-button:hover {
      color: #0e2759;
      border-color: rgba(14, 39, 89, 0.2); }
  /* line 63, src/assets/scss/components/_buttons.scss */
  .button.google-button {
    background-color: #db4437;
    color: #ffffff;
    border-color: #db4437;
    position: relative; }
    /* line 68, src/assets/scss/components/_buttons.scss */
    .button.google-button img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px; }
    /* line 75, src/assets/scss/components/_buttons.scss */
    .button.google-button:hover {
      background-color: #bd2e22; }

/* line 81, src/assets/scss/components/_buttons.scss */
.hover-for-links {
  transition: color 0.3s ease-in-out;
  position: relative; }
  /* line 84, src/assets/scss/components/_buttons.scss */
  .hover-for-links::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    transform: scaleX(0);
    transition: transform 0.4s ease-in-out;
    transform-origin: right; }
  /* line 96, src/assets/scss/components/_buttons.scss */
  .hover-for-links:hover, .hover-for-links:focus {
    text-decoration: none; }
    /* line 99, src/assets/scss/components/_buttons.scss */
    .hover-for-links:hover::before, .hover-for-links:focus::before {
      transform: scaleX(1);
      transform-origin: left; }

/* line 106, src/assets/scss/components/_buttons.scss */
.social-buttons-separator {
  margin: 15px 0; }
  /* line 108, src/assets/scss/components/_buttons.scss */
  .social-buttons-separator div {
    width: 100%;
    flex-grow: 2;
    height: 1px;
    background-color: rgba(26, 26, 26, 0.15); }
  /* line 114, src/assets/scss/components/_buttons.scss */
  .social-buttons-separator span {
    font-weight: 400;
    flex-grow: 1;
    white-space: nowrap;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.6rem;
    padding: 0 20px; }
    @media (max-width: 575.98px) {
      /* line 114, src/assets/scss/components/_buttons.scss */
      .social-buttons-separator span {
        padding: 0 10px; } }

/* line 128, src/assets/scss/components/_buttons.scss */
.log-out-button {
  font-size: 0.75rem;
  color: #000000;
  padding: 17px 0;
  color: red; }
  @media (max-width: 992.98px) {
    /* line 128, src/assets/scss/components/_buttons.scss */
    .log-out-button {
      height: 75px; } }
  @media (max-width: 575.98px) {
    /* line 128, src/assets/scss/components/_buttons.scss */
    .log-out-button {
      height: 55px; } }

/* line 1, src/assets/scss/components/_cards.scss */
.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer; }
  @media (max-width: 575.98px) {
    /* line 1, src/assets/scss/components/_cards.scss */
    .product-card {
      margin: 0 auto; } }
  /* line 11, src/assets/scss/components/_cards.scss */
  .product-card h4 {
    font-size: 1.2rem;
    line-height: 1.666em;
    font-family: "PlayfairDisplay";
    transition: all 0.2s ease-in-out; }
  /* line 17, src/assets/scss/components/_cards.scss */
  .product-card p {
    opacity: 0.6;
    font-size: 0.75rem;
    margin-bottom: 10px; }
    /* line 22, src/assets/scss/components/_cards.scss */
    .product-card p.price {
      font-size: 0.8rem;
      color: #0e2759;
      opacity: 1;
      margin-bottom: 30px; }
      @media (max-width: 575.98px) {
        /* line 22, src/assets/scss/components/_cards.scss */
        .product-card p.price {
          margin-bottom: 15px; } }
  /* line 32, src/assets/scss/components/_cards.scss */
  .product-card .button {
    margin-top: auto; }
  /* line 37, src/assets/scss/components/_cards.scss */
  .product-card.product-card-2 .img-wrap, .product-card.product-card-3 .img-wrap {
    padding-bottom: 4px;
    padding-right: 4px;
    height: 234px; }
    /* line 41, src/assets/scss/components/_cards.scss */
    .product-card.product-card-2 .img-wrap img, .product-card.product-card-3 .img-wrap img {
      object-position: top; }
    @media (max-width: 575.98px) {
      /* line 37, src/assets/scss/components/_cards.scss */
      .product-card.product-card-2 .img-wrap, .product-card.product-card-3 .img-wrap {
        height: 280px; } }
    @media (max-width: 419px) {
      /* line 37, src/assets/scss/components/_cards.scss */
      .product-card.product-card-2 .img-wrap, .product-card.product-card-3 .img-wrap {
        height: 210px; } }
    @media (max-width: 360px) {
      /* line 37, src/assets/scss/components/_cards.scss */
      .product-card.product-card-2 .img-wrap, .product-card.product-card-3 .img-wrap {
        height: 180px; } }
    /* line 53, src/assets/scss/components/_cards.scss */
    .product-card.product-card-2 .img-wrap:after, .product-card.product-card-3 .img-wrap:after {
      top: 4px;
      left: 4px; }
  /* line 58, src/assets/scss/components/_cards.scss */
  .product-card.product-card-2 h4, .product-card.product-card-3 h4 {
    font-size: 0.8rem;
    line-height: 1.5em; }
  /* line 62, src/assets/scss/components/_cards.scss */
  .product-card.product-card-2 p, .product-card.product-card-3 p {
    margin-bottom: 20px;
    font-size: 0.65rem; }
  /* line 66, src/assets/scss/components/_cards.scss */
  .product-card.product-card-2 h6, .product-card.product-card-3 h6 {
    color: #0e2759;
    line-height: 1.3333em;
    font-weight: 400;
    font-size: 0.75rem; }
    /* line 71, src/assets/scss/components/_cards.scss */
    .product-card.product-card-2 h6:hover, .product-card.product-card-3 h6:hover {
      text-decoration: underline; }
  @media (max-width: 1279.98px) {
    /* line 80, src/assets/scss/components/_cards.scss */
    .product-card.product-card-3 .img-wrap {
      height: 270px; } }
  @media (max-width: 767.98px) {
    /* line 85, src/assets/scss/components/_cards.scss */
    .product-card.product-card-3 .img-wrap {
      height: 320px; } }
  @media (max-width: 575.98px) {
    /* line 90, src/assets/scss/components/_cards.scss */
    .product-card.product-card-3 .img-wrap {
      height: 320px; } }
  @media (max-width: 500px) {
    /* line 95, src/assets/scss/components/_cards.scss */
    .product-card.product-card-3 .img-wrap {
      height: 260px; } }
  @media (max-width: 419px) {
    /* line 100, src/assets/scss/components/_cards.scss */
    .product-card.product-card-3 .img-wrap {
      height: 220px; } }
  @media (max-width: 360px) {
    /* line 105, src/assets/scss/components/_cards.scss */
    .product-card.product-card-3 .img-wrap {
      height: 180px; } }
  /* line 110, src/assets/scss/components/_cards.scss */
  .product-card.product-card-3 p {
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 1.5em;
    opacity: 1; }
  /* line 119, src/assets/scss/components/_cards.scss */
  .product-card.product-card-combo {
    max-width: 530px; }
    /* line 121, src/assets/scss/components/_cards.scss */
    .product-card.product-card-combo .card-wrap {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding: 40px;
      height: 380px;
      background-color: rgba(14, 39, 89, 0.05);
      margin-bottom: 20px; }
      @media (max-width: 1279.98px) {
        /* line 121, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .card-wrap {
          height: 310px; } }
      @media (max-width: 992.98px) {
        /* line 121, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .card-wrap {
          padding: 30px;
          height: 280px;
          margin-bottom: 10px; } }
      @media (max-width: 767.98px) {
        /* line 121, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .card-wrap {
          height: 200px;
          padding: 20px; } }
      @media (max-width: 575.98px) {
        /* line 121, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .card-wrap {
          height: 300px;
          justify-content: center;
          gap: 0 40px; } }
      @media (max-width: 419px) {
        /* line 121, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .card-wrap {
          height: 260px; } }
      @media (max-width: 360px) {
        /* line 121, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .card-wrap {
          height: 230px; } }
      /* line 154, src/assets/scss/components/_cards.scss */
      .product-card.product-card-combo .card-wrap > img {
        max-width: 90px;
        z-index: 5; }
        @media (max-width: 992.98px) {
          /* line 154, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .card-wrap > img {
            max-width: 60px; } }
        @media (max-width: 767.98px) {
          /* line 154, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .card-wrap > img {
            max-width: 50px; } }
        @media (max-width: 575.98px) {
          /* line 154, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .card-wrap > img {
            max-width: 90px; } }
        @media (max-width: 419px) {
          /* line 154, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .card-wrap > img {
            max-width: 70px; } }
        @media (max-width: 360px) {
          /* line 154, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .card-wrap > img {
            max-width: 50px; } }
      /* line 175, src/assets/scss/components/_cards.scss */
      .product-card.product-card-combo .card-wrap:after {
        content: none; }
    /* line 179, src/assets/scss/components/_cards.scss */
    .product-card.product-card-combo .img-wrap {
      height: fit-content;
      max-width: 186px;
      margin-bottom: 0;
      padding-right: 6px;
      padding-bottom: 6px; }
      /* line 185, src/assets/scss/components/_cards.scss */
      .product-card.product-card-combo .img-wrap:after {
        top: 6px;
        left: 6px; }
      @media (max-width: 1279.98px) {
        /* line 179, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .img-wrap {
          max-width: 150px; } }
      @media (max-width: 992.98px) {
        /* line 179, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .img-wrap {
          max-width: 120px; } }
      @media (max-width: 767.98px) {
        /* line 179, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .img-wrap {
          max-width: 90px; } }
      @media (max-width: 575.98px) {
        /* line 179, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .img-wrap {
          max-width: 165px;
          padding-right: 10px;
          padding-bottom: 10px; }
          /* line 202, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .img-wrap:after {
            top: 10px;
            left: 10px; } }
      @media (max-width: 360px) {
        /* line 179, src/assets/scss/components/_cards.scss */
        .product-card.product-card-combo .img-wrap {
          padding-right: 5px;
          padding-bottom: 5px; }
          /* line 210, src/assets/scss/components/_cards.scss */
          .product-card.product-card-combo .img-wrap:after {
            top: 5px;
            left: 5px; } }
      /* line 215, src/assets/scss/components/_cards.scss */
      .product-card.product-card-combo .img-wrap img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
      /* line 220, src/assets/scss/components/_cards.scss */
      .product-card.product-card-combo .img-wrap:nth-of-type(1) {
        margin-bottom: auto; }
      /* line 223, src/assets/scss/components/_cards.scss */
      .product-card.product-card-combo .img-wrap:nth-of-type(2) {
        margin-top: auto; }
  /* line 229, src/assets/scss/components/_cards.scss */
  .product-card:hover h4 {
    color: #ff7a00; }

/* line 1, src/assets/scss/components/_checkboxes.scss */
.custom-checkbox {
  cursor: pointer; }
  /* line 3, src/assets/scss/components/_checkboxes.scss */
  .custom-checkbox input, .custom-checkbox select.select-custom {
    display: none; }
  /* line 6, src/assets/scss/components/_checkboxes.scss */
  .custom-checkbox label {
    display: flex;
    font-size: 0.7rem;
    line-height: 28px;
    font-weight: 400; }
    /* line 12, src/assets/scss/components/_checkboxes.scss */
    .custom-checkbox label p {
      font-size: 0.7rem;
      padding-top: 3px; }
    /* line 17, src/assets/scss/components/_checkboxes.scss */
    .custom-checkbox label .checkmark {
      position: relative;
      height: 28px;
      width: 28px;
      border: 1px solid rgba(14, 39, 89, 0.2);
      border-radius: 2px;
      background-color: #ffffff;
      margin-right: 15px;
      flex-shrink: 0;
      cursor: pointer; }
      /* line 27, src/assets/scss/components/_checkboxes.scss */
      .custom-checkbox label .checkmark:after {
        content: none;
        position: absolute;
        background-image: url("../../assets/img/checkmark.svg");
        background-size: contain;
        height: 13px;
        width: 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  /* line 40, src/assets/scss/components/_checkboxes.scss */
  .custom-checkbox input:checked ~ label .checkmark:after, .custom-checkbox select.select-custom:checked ~ label .checkmark:after {
    content: ""; }

/* line 46, src/assets/scss/components/_checkboxes.scss */
.subscribe-section .custom-checkbox .checkmark {
  border: 1px solid #ff7a00; }

/* line 51, src/assets/scss/components/_checkboxes.scss */
.manual-input-option {
  display: none; }
  /* line 53, src/assets/scss/components/_checkboxes.scss */
  .manual-input-option ~ label {
    display: flex;
    font-size: 0.7rem;
    line-height: 28px; }
    /* line 57, src/assets/scss/components/_checkboxes.scss */
    .manual-input-option ~ label .checkmark {
      position: relative;
      height: 28px;
      width: 28px;
      border: 1px solid rgba(14, 39, 89, 0.2);
      border-radius: 2px;
      background-color: #ffffff;
      margin-right: 15px;
      flex-shrink: 0; }
      /* line 66, src/assets/scss/components/_checkboxes.scss */
      .manual-input-option ~ label .checkmark:after {
        content: none;
        position: absolute;
        background-image: url("../../assets/img/checkmark.svg");
        background-size: contain;
        height: 13px;
        width: 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    /* line 78, src/assets/scss/components/_checkboxes.scss */
    .manual-input-option ~ label ~ .input-wrap {
      display: none; }
      /* line 80, src/assets/scss/components/_checkboxes.scss */
      .manual-input-option ~ label ~ .input-wrap:nth-of-type(2) {
        display: block; }
  /* line 87, src/assets/scss/components/_checkboxes.scss */
  .manual-input-option:checked ~ label .checkmark:after {
    content: ""; }
  /* line 90, src/assets/scss/components/_checkboxes.scss */
  .manual-input-option:checked ~ label ~ .input-wrap {
    display: block; }
    /* line 92, src/assets/scss/components/_checkboxes.scss */
    .manual-input-option:checked ~ label ~ .input-wrap:nth-of-type(2) {
      display: none; }

@media (min-width: 1280px) {
  /* line 1, src/assets/scss/components/_containers.scss */
  .container-md {
    max-width: 1030px; } }

/* line 8, src/assets/scss/components/_containers.scss */
.about-text-block h2 {
  text-align: center;
  margin-bottom: 20px; }

/* line 12, src/assets/scss/components/_containers.scss */
.about-text-block p {
  line-height: 1.375em;
  font-size: 0.8rem;
  margin-bottom: 30px; }
  @media (max-width: 575.98px) {
    /* line 12, src/assets/scss/components/_containers.scss */
    .about-text-block p {
      font-size: 0.7rem;
      margin-bottom: 20px; } }

/* line 1, src/assets/scss/components/_dropdowns.scss */
.actions-tooltip {
  margin-left: 30px; }
  /* line 3, src/assets/scss/components/_dropdowns.scss */
  .actions-tooltip .dropdown-toggle {
    height: 32px;
    width: 32px;
    background-color: rgba(14, 39, 89, 0.1);
    border-radius: 50%; }
    /* line 8, src/assets/scss/components/_dropdowns.scss */
    .actions-tooltip .dropdown-toggle:after {
      content: none; }
  /* line 12, src/assets/scss/components/_dropdowns.scss */
  .actions-tooltip .dropdown-menu {
    margin-top: 15px !important;
    border: 1px solid rgba(14, 39, 89, 0.2);
    padding: 5px 20px; }
    /* line 17, src/assets/scss/components/_dropdowns.scss */
    .actions-tooltip .dropdown-menu:after {
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      transform: rotate(45deg);
      top: -7px;
      right: 8px;
      background-color: #ffffff;
      border-left: 1px solid rgba(14, 39, 89, 0.2);
      border-top: 1px solid rgba(14, 39, 89, 0.2); }
    /* line 29, src/assets/scss/components/_dropdowns.scss */
    .actions-tooltip .dropdown-menu .dropdown-item {
      font-size: 0.7rem;
      line-height: 40px;
      padding: 0; }
      /* line 33, src/assets/scss/components/_dropdowns.scss */
      .actions-tooltip .dropdown-menu .dropdown-item:hover {
        text-decoration: underline;
        background-color: transparent; }
      /* line 38, src/assets/scss/components/_dropdowns.scss */
      .actions-tooltip .dropdown-menu .dropdown-item.active, .actions-tooltip .dropdown-menu .dropdown-item:focus, .actions-tooltip .dropdown-menu .dropdown-item:active, .actions-tooltip .dropdown-menu .dropdown-item:focus-visible {
        background-color: transparent;
        color: inherit;
        outline: none;
        border: none; }

/* line 5, src/assets/scss/components/_forms.scss */
.input-wrap {
  width: 100%; }
  /* line 8, src/assets/scss/components/_forms.scss */
  .input-wrap > label {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.37em;
    margin-bottom: 10px; }

/* line 16, src/assets/scss/components/_forms.scss */
input, select.select-custom, textarea {
  width: 100%;
  border: 1px solid rgba(14, 39, 89, 0.2);
  border-radius: 2px;
  height: 46px;
  font-size: 0.75rem;
  line-height: 1.333em;
  padding: 0 20px;
  background-color: #ffffff; }
  @media (max-width: 575.98px) {
    /* line 16, src/assets/scss/components/_forms.scss */
    input, select.select-custom, textarea {
      padding: 0 15px; } }
  /* line 29, src/assets/scss/components/_forms.scss */
  input ::placeholder, select.select-custom ::placeholder, textarea ::placeholder {
    opacity: 0.6; }
  /* line 32, src/assets/scss/components/_forms.scss */
  input:focus, select.select-custom:focus, input:focus-visible, select.select-custom:focus-visible, input:focus-within, select.select-custom:focus-within, input:active, select.select-custom:active, input:placeholder-shown, select.select-custom:placeholder-shown, textarea:focus, textarea:focus-visible, textarea:focus-within, textarea:active, textarea:placeholder-shown {
    outline: none; }
  /* line 36, src/assets/scss/components/_forms.scss */
  input:read-only, select.select-custom:read-only, textarea:read-only {
    background-color: #f3f4f7;
    border: none; }

/* line 42, src/assets/scss/components/_forms.scss */
textarea {
  resize: none;
  padding: 12px 20px; }

/* line 47, src/assets/scss/components/_forms.scss */
select.select-custom {
  background-color: #ffffff;
  background-image: url("../../assets/img/chevron-down.svg");
  color: #0e2759; }
  /* line 52, src/assets/scss/components/_forms.scss */
  select.select-custom option {
    color: #0e2759; }
  /* line 55, src/assets/scss/components/_forms.scss */
  select.select-custom:read-only {
    background-color: #ffffff;
    border: 1px solid rgba(14, 39, 89, 0.2); }
  /* line 59, src/assets/scss/components/_forms.scss */
  select.select-custom:disabled {
    opacity: 0.6;
    background-color: rgba(0, 0, 0, 0.05);
    color: #000000; }

/* line 66, src/assets/scss/components/_forms.scss */
form.auth-form {
  display: flex;
  flex-direction: column;
  gap: 10px; }
  /* line 71, src/assets/scss/components/_forms.scss */
  form.auth-form > .d-flex {
    gap: 10px; }
  /* line 74, src/assets/scss/components/_forms.scss */
  form.auth-form .link {
    font-size: 0.75rem; }

/* line 85, src/assets/scss/components/_forms.scss */
.photo-upload-wrap label {
  margin-bottom: 0; }
  /* line 87, src/assets/scss/components/_forms.scss */
  .photo-upload-wrap label ~ p {
    font-size: 0.6rem;
    line-height: 1.666em;
    margin-bottom: 10px; }

/* line 94, src/assets/scss/components/_forms.scss */
.custom-photo-upload {
  width: 50%;
  border: 1px solid rgba(14, 39, 89, 0.2);
  height: 46px;
  padding: 0 20px; }
  @media (max-width: 575.98px) {
    /* line 94, src/assets/scss/components/_forms.scss */
    .custom-photo-upload {
      width: 100%; } }
  /* line 102, src/assets/scss/components/_forms.scss */
  .custom-photo-upload .uploader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    /* line 107, src/assets/scss/components/_forms.scss */
    .custom-photo-upload .uploader span {
      color: #0e2759;
      font-size: 0.75rem; }
  /* line 113, src/assets/scss/components/_forms.scss */
  .custom-photo-upload input, .custom-photo-upload select.select-custom {
    display: none; }

/* line 1, src/assets/scss/components/_footer.scss */
footer {
  padding: 60px 0 28px 0;
  background-color: #040B1A; }
  /* line 4, src/assets/scss/components/_footer.scss */
  footer * {
    color: #ffffff; }
  /* line 9, src/assets/scss/components/_footer.scss */
  footer .row:first-of-type {
    padding-bottom: 80px; }
    @media (max-width: 575.98px) {
      /* line 9, src/assets/scss/components/_footer.scss */
      footer .row:first-of-type {
        padding-bottom: 40px; } }
    /* line 14, src/assets/scss/components/_footer.scss */
    footer .row:first-of-type p {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 35px; }
    /* line 19, src/assets/scss/components/_footer.scss */
    footer .row:first-of-type h6 {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 17px;
      margin-bottom: 20px;
      font-family: "Open Sans";
      opacity: 0.4; }
    /* line 28, src/assets/scss/components/_footer.scss */
    footer .row:first-of-type a {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;
      display: block; }
    @media (max-width: 360px) {
      /* line 34, src/assets/scss/components/_footer.scss */
      footer .row:first-of-type > div {
        width: 100% !important;
        max-width: 100% !important;
        text-align: start !important; } }
  /* line 43, src/assets/scss/components/_footer.scss */
  footer .row:nth-of-type(2) p {
    opacity: 0.4;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px; }
  /* line 50, src/assets/scss/components/_footer.scss */
  footer .row:nth-of-type(2) img {
    width: 129px; }
  /* line 53, src/assets/scss/components/_footer.scss */
  footer .row:nth-of-type(2) > div {
    padding-top: 22px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.05); }

/* line 1, src/assets/scss/components/_gallery.scss */
.img-wrap {
  position: relative;
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px; }
  @media (max-width: 767.98px) {
    /* line 1, src/assets/scss/components/_gallery.scss */
    .img-wrap {
      margin-bottom: 5px; } }
  /* line 11, src/assets/scss/components/_gallery.scss */
  .img-wrap:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    left: 10px;
    bottom: 0;
    background-color: rgba(14, 39, 89, 0.1); }
  /* line 20, src/assets/scss/components/_gallery.scss */
  .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  /* line 25, src/assets/scss/components/_gallery.scss */
  .img-wrap.img-wrap-xs {
    padding-right: 3px;
    padding-bottom: 3px; }
    /* line 28, src/assets/scss/components/_gallery.scss */
    .img-wrap.img-wrap-xs:after {
      top: 3px;
      left: 3px; }

/* line 1, src/assets/scss/components/_labels.scss */
.selection-label {
  position: relative;
  height: 22px;
  font-size: 0.5rem;
  line-height: 22px;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 3px;
  margin-left: 10px;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 3; }
  /* line 13, src/assets/scss/components/_labels.scss */
  .selection-label:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    top: 3px;
    right: -8px;
    border-radius: 2px;
    transform: rotate(45deg);
    z-index: -1; }
  /* line 25, src/assets/scss/components/_labels.scss */
  .selection-label.discount-label {
    background-color: #F7CA00; }
    /* line 27, src/assets/scss/components/_labels.scss */
    .selection-label.discount-label:after {
      background-color: #F7CA00; }
  /* line 31, src/assets/scss/components/_labels.scss */
  .selection-label.gift-label {
    background-color: red;
    color: #ffffff; }
    /* line 34, src/assets/scss/components/_labels.scss */
    .selection-label.gift-label:after {
      background-color: red; }
  @media (max-width: 575.98px) {
    /* line 1, src/assets/scss/components/_labels.scss */
    .selection-label {
      position: absolute;
      top: 10px;
      left: -10px; } }

/* line 46, src/assets/scss/components/_labels.scss */
.subscription-info img {
  margin-right: 15px; }
  @media (max-width: 767.98px) {
    /* line 46, src/assets/scss/components/_labels.scss */
    .subscription-info img {
      margin-right: 8px; } }

/* line 52, src/assets/scss/components/_labels.scss */
.subscription-info span {
  font-size: 0.7rem;
  line-height: 1.756em; }

/* line 58, src/assets/scss/components/_labels.scss */
.items-in-a-cart {
  position: absolute;
  background-color: #ff7a00;
  color: #ffffff;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  font-size: 0.6rem;
  line-height: 19px;
  text-align: center;
  top: -10px;
  right: -10px; }

/* line 1, src/assets/scss/components/_links.scss */
a:hover {
  color: #ff7a00; }

/* line 5, src/assets/scss/components/_links.scss */
.link {
  text-decoration: underline; }
  /* line 7, src/assets/scss/components/_links.scss */
  .link:hover {
    text-decoration: none;
    color: inherit; }

/* line 13, src/assets/scss/components/_links.scss */
.add-more {
  font-size: 0.75rem;
  color: #0e2759; }

/* line 19, src/assets/scss/components/_links.scss */
.link-to-user-dashboard img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center; }

/* line 1, src/assets/scss/components/_modal.scss */
.modal {
  z-index: 20; }
  /* line 3, src/assets/scss/components/_modal.scss */
  .modal p {
    font-size: 0.8rem;
    line-height: 1.375em;
    margin-bottom: 20px; }
    @media (max-width: 575.98px) {
      /* line 3, src/assets/scss/components/_modal.scss */
      .modal p {
        font-size: 0.7rem; } }
    /* line 10, src/assets/scss/components/_modal.scss */
    .modal p:last-of-type {
      margin-bottom: 0; }
  /* line 14, src/assets/scss/components/_modal.scss */
  .modal .modal-dialog {
    max-width: 996px; }
    @media (max-width: 1279.98px) {
      /* line 14, src/assets/scss/components/_modal.scss */
      .modal .modal-dialog {
        max-width: 800px; } }
    @media (max-width: 992.98px) {
      /* line 14, src/assets/scss/components/_modal.scss */
      .modal .modal-dialog {
        max-width: 700px; } }
    @media (max-width: 767.98px) {
      /* line 14, src/assets/scss/components/_modal.scss */
      .modal .modal-dialog {
        max-width: 550px; } }
  /* line 26, src/assets/scss/components/_modal.scss */
  .modal .modal-content {
    border-radius: 0;
    border: none; }
  /* line 30, src/assets/scss/components/_modal.scss */
  .modal .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 3; }
  /* line 36, src/assets/scss/components/_modal.scss */
  .modal .modal-body {
    padding: 0; }
    /* line 39, src/assets/scss/components/_modal.scss */
    .modal .modal-body .modal-right {
      flex: 1;
      padding: 60px 60px 70px 60px;
      background-color: #f3f4f7; }
      @media (max-width: 1279.98px) {
        /* line 39, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-right {
          padding: 40px 40px 55px 40px; } }
      @media (max-width: 767.98px) {
        /* line 39, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-right {
          padding: 30px 20px 35px 20px; } }
      @media (max-width: 575.98px) {
        /* line 39, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-right {
          padding: 20px 20px 20px 20px; } }
      /* line 52, src/assets/scss/components/_modal.scss */
      .modal .modal-body .modal-right .button {
        height: 54px; }
        @media (max-width: 575.98px) {
          /* line 52, src/assets/scss/components/_modal.scss */
          .modal .modal-body .modal-right .button {
            height: 46px;
            font-size: 0.7rem; } }
    /* line 61, src/assets/scss/components/_modal.scss */
    .modal .modal-body .modal-left {
      padding: 60px 60px 70px 60px;
      background-color: #ffffff;
      flex: 1; }
      @media (max-width: 1279.98px) {
        /* line 61, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-left {
          padding: 40px 40px 55px 40px; } }
      @media (max-width: 767.98px) {
        /* line 61, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-left {
          padding: 30px 20px 35px 20px; } }
      @media (max-width: 575.98px) {
        /* line 61, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-left {
          padding: 20px 20px 20px 20px; } }
      @media (max-width: 767.98px) {
        /* line 74, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-left .button {
          font-size: 0.7rem; }
          /* line 77, src/assets/scss/components/_modal.scss */
          .modal .modal-body .modal-left .button img {
            left: 15px;
            max-width: 20px; } }
      @media (max-width: 575.98px) {
        /* line 74, src/assets/scss/components/_modal.scss */
        .modal .modal-body .modal-left .button {
          height: 46px; }
          /* line 85, src/assets/scss/components/_modal.scss */
          .modal .modal-body .modal-left .button img {
            left: 20px;
            max-width: 30px; } }
  /* line 93, src/assets/scss/components/_modal.scss */
  .modal .modal-title {
    font-size: 1.8rem;
    line-height: 1.278em;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      /* line 93, src/assets/scss/components/_modal.scss */
      .modal .modal-title {
        font-size: 1.2rem; } }
    @media (max-width: 575.98px) {
      /* line 93, src/assets/scss/components/_modal.scss */
      .modal .modal-title {
        margin-bottom: 20px; } }
  /* line 105, src/assets/scss/components/_modal.scss */
  .modal.side-modal {
    transform: translateX(110%);
    transition: all 0.5s ease-in-out; }
    /* line 109, src/assets/scss/components/_modal.scss */
    .modal.side-modal h3 {
      padding-right: 50px;
      font-family: 'PlayfairDisplay'; }
    /* line 114, src/assets/scss/components/_modal.scss */
    .modal.side-modal .modal-dialog {
      margin: 0 0 0 auto;
      min-height: 100vh;
      max-width: 795px; }
    /* line 119, src/assets/scss/components/_modal.scss */
    .modal.side-modal .modal-body {
      min-height: 100vh; }
    /* line 122, src/assets/scss/components/_modal.scss */
    .modal.side-modal .modal-content {
      height: 100%;
      border-radius: 0;
      border: none; }
    /* line 127, src/assets/scss/components/_modal.scss */
    .modal.side-modal.show {
      transform: translate(0); }
    /* line 130, src/assets/scss/components/_modal.scss */
    .modal.side-modal .modal-body {
      padding: 25px 25px 45px 25px; }
    /* line 133, src/assets/scss/components/_modal.scss */
    .modal.side-modal textarea {
      height: 110px; }
    /* line 136, src/assets/scss/components/_modal.scss */
    .modal.side-modal .button {
      height: 46px;
      border-radius: 2px;
      font-size: 0.75rem; }
  /* line 143, src/assets/scss/components/_modal.scss */
  .modal.modal-small .modal-dialog {
    max-width: 470px; }
  /* line 149, src/assets/scss/components/_modal.scss */
  .modal.payment-modal .modal-dialog {
    max-width: 588px; }
  /* line 153, src/assets/scss/components/_modal.scss */
  .modal.payment-modal .modal-content {
    border-radius: 0;
    border: none; }
  /* line 157, src/assets/scss/components/_modal.scss */
  .modal.payment-modal .modal-body {
    padding: 70px 100px 80px 100px;
    text-align: center; }
    @media (max-width: 575.98px) {
      /* line 157, src/assets/scss/components/_modal.scss */
      .modal.payment-modal .modal-body {
        padding: 50px 40px 60px 40px; } }
    /* line 163, src/assets/scss/components/_modal.scss */
    .modal.payment-modal .modal-body .modal-title {
      margin-top: 50px;
      margin-bottom: 20px; }
    /* line 167, src/assets/scss/components/_modal.scss */
    .modal.payment-modal .modal-body p {
      margin-bottom: 60px; }

/* line 174, src/assets/scss/components/_modal.scss */
.modal-backdrop.show {
  opacity: 0.6; }

/* line 1, src/assets/scss/components/_nav.scss */
header {
  background-color: #ffffff; }

@media (max-width: 1279.98px) {
  /* line 6, src/assets/scss/components/_nav.scss */
  nav .logo img {
    width: 220px; } }

@media (max-width: 419px) {
  /* line 6, src/assets/scss/components/_nav.scss */
  nav .logo img {
    width: 180px; } }

@media (max-width: 992.98px) {
  /* line 16, src/assets/scss/components/_nav.scss */
  nav .navbar-collapse {
    background-color: #0e2759;
    position: fixed;
    width: 50vw;
    top: 0;
    padding-top: 120px;
    left: auto;
    overflow-y: auto;
    height: 100vh;
    right: -120%;
    transition: .3s;
    z-index: 17; }
    /* line 30, src/assets/scss/components/_nav.scss */
    nav .navbar-collapse.show {
      right: 0; } }

@media (max-width: 575.98px) {
  /* line 16, src/assets/scss/components/_nav.scss */
  nav .navbar-collapse {
    width: 100vw; } }

/* line 38, src/assets/scss/components/_nav.scss */
nav .navbar-collapse > a {
  margin-left: 50px;
  font-size: 0.7rem;
  text-transform: uppercase; }
  /* line 42, src/assets/scss/components/_nav.scss */
  nav .navbar-collapse > a:hover {
    color: #ff7a00; }
    /* line 45, src/assets/scss/components/_nav.scss */
    nav .navbar-collapse > a:hover svg * {
      fill: #ff7a00; }
  @media (max-width: 1279.98px) {
    /* line 38, src/assets/scss/components/_nav.scss */
    nav .navbar-collapse > a {
      margin-left: 30px; } }
  @media (max-width: 992.98px) {
    /* line 38, src/assets/scss/components/_nav.scss */
    nav .navbar-collapse > a {
      margin-left: 0;
      margin-bottom: 40px;
      color: #ffffff; }
      /* line 58, src/assets/scss/components/_nav.scss */
      nav .navbar-collapse > a svg * {
        fill: #ffffff; } }

/* line 2, src/assets/scss/components/_nav-toggler.scss */
nav .navbar-toggler {
  z-index: 18;
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ffffff;
  position: relative; }
  /* line 10, src/assets/scss/components/_nav-toggler.scss */
  nav .navbar-toggler:focus, nav .navbar-toggler:active, nav .navbar-toggler:focus-within, nav .navbar-toggler:focus-visible {
    box-shadow: 5px 5px 15px 5px rgba(51, 51, 51, 0.03); }

/* line 15, src/assets/scss/components/_nav-toggler.scss */
nav button.navbar-toggler label {
  position: absolute;
  height: 8px;
  width: 20px;
  z-index: 12;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  /* line 24, src/assets/scss/components/_nav-toggler.scss */
  nav button.navbar-toggler label span {
    position: absolute;
    width: 100%;
    height: 1px;
    display: block;
    transition: .5s;
    background-color: #000000; }
  /* line 33, src/assets/scss/components/_nav-toggler.scss */
  nav button.navbar-toggler label span:first-child {
    top: 0; }
  /* line 37, src/assets/scss/components/_nav-toggler.scss */
  nav button.navbar-toggler label span:last-child {
    bottom: 0;
    width: 75%; }

/* line 43, src/assets/scss/components/_nav-toggler.scss */
nav button.navbar-toggler:not(.collapsed) {
  background-color: #ff7a00; }
  /* line 47, src/assets/scss/components/_nav-toggler.scss */
  nav button.navbar-toggler:not(.collapsed) label span:first-child {
    transform: rotate(405deg);
    top: 4px; }
  /* line 52, src/assets/scss/components/_nav-toggler.scss */
  nav button.navbar-toggler:not(.collapsed) label span:last-child {
    transform: rotate(-405deg);
    width: 100%;
    bottom: 3px; }

@media (min-width: 576px) {
  /* line 1, src/assets/scss/components/_radios.scss */
  .product-select-radio {
    min-width: 33.3333%; } }

/* line 5, src/assets/scss/components/_radios.scss */
.product-select-radio input, .product-select-radio select.select-custom {
  display: none; }

/* line 8, src/assets/scss/components/_radios.scss */
.product-select-radio label.product-radio-label {
  display: flex;
  height: 100%;
  flex-direction: column;
  text-align: center;
  padding: 20px 20px 25px 20px;
  background-color: white;
  border: 1px solid rgba(14, 39, 89, 0.2);
  cursor: pointer; }
  @media (max-width: 575.98px) {
    /* line 8, src/assets/scss/components/_radios.scss */
    .product-select-radio label.product-radio-label {
      padding: 15px;
      display: grid;
      grid-template-columns: 120px 1fr; } }
  @media (max-width: 360px) {
    /* line 8, src/assets/scss/components/_radios.scss */
    .product-select-radio label.product-radio-label {
      grid-template-columns: 100px 1fr; } }
  /* line 25, src/assets/scss/components/_radios.scss */
  .product-select-radio label.product-radio-label h6 {
    font-size: 0.8rem;
    line-height: 1.5625em;
    font-family: "Open Sans";
    margin-bottom: 20px; }
    @media (max-width: 1279.98px) {
      /* line 25, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label h6 {
        white-space: nowrap;
        font-size: 0.7rem; } }
    @media (max-width: 575.98px) {
      /* line 25, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label h6 {
        margin-bottom: 10px;
        grid-column: 2/3;
        font-size: 0.9rem; } }
    @media (max-width: 419px) {
      /* line 25, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label h6 {
        font-size: 0.8rem; } }
    @media (max-width: 360px) {
      /* line 25, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label h6 {
        font-size: 0.7rem; } }
  /* line 46, src/assets/scss/components/_radios.scss */
  .product-select-radio label.product-radio-label .price {
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: auto; }
    @media (max-width: 575.98px) {
      /* line 46, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label .price {
        margin-top: unset; } }
    @media (max-width: 360px) {
      /* line 46, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label .price {
        font-size: 0.7rem; } }
  /* line 58, src/assets/scss/components/_radios.scss */
  .product-select-radio label.product-radio-label p:not(.price) {
    font-size: 0.7rem; }
  /* line 61, src/assets/scss/components/_radios.scss */
  .product-select-radio label.product-radio-label img {
    width: 100%;
    max-height: 140px;
    margin-top: auto; }
    @media (max-width: 575.98px) {
      /* line 61, src/assets/scss/components/_radios.scss */
      .product-select-radio label.product-radio-label img {
        max-height: 120px;
        margin-bottom: 10px; } }
  /* line 71, src/assets/scss/components/_radios.scss */
  .product-select-radio label.product-radio-label:hover {
    border-color: rgba(14, 39, 89, 0.5);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05); }
  /* line 75, src/assets/scss/components/_radios.scss */
  .product-select-radio label.product-radio-label:active, .product-select-radio label.product-radio-label:focus {
    border-color: #0e2759;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05); }

/* line 80, src/assets/scss/components/_radios.scss */
.product-select-radio input:checked ~ label, .product-select-radio select.select-custom:checked ~ label {
  border-color: #0e2759 !important;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.05); }

/* line 85, src/assets/scss/components/_radios.scss */
.product-select-radio .img-wrap {
  width: 100px;
  height: 140px;
  margin: 0 auto;
  padding-bottom: 3px;
  padding-right: 3px;
  margin-bottom: 20px; }
  @media (max-width: 575.98px) {
    /* line 85, src/assets/scss/components/_radios.scss */
    .product-select-radio .img-wrap {
      width: 90px;
      height: 120px;
      grid-row: 1/4;
      margin-bottom: 0px; } }
  @media (max-width: 419px) {
    /* line 85, src/assets/scss/components/_radios.scss */
    .product-select-radio .img-wrap {
      margin: 0;
      margin-right: 10px; } }
  /* line 102, src/assets/scss/components/_radios.scss */
  .product-select-radio .img-wrap:after {
    top: 3px;
    left: 3px; }

/* line 109, src/assets/scss/components/_radios.scss */
.duration-radio {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(14, 39, 89, 0.2);
  margin-bottom: 5px;
  position: relative; }
  /* line 115, src/assets/scss/components/_radios.scss */
  .duration-radio * {
    white-space: nowrap; }
  @media (max-width: 1279.98px) {
    /* line 109, src/assets/scss/components/_radios.scss */
    .duration-radio {
      padding: 15px; } }
  @media (max-width: 992.98px) {
    /* line 109, src/assets/scss/components/_radios.scss */
    .duration-radio {
      padding: 20px; } }
  @media (max-width: 767.98px) {
    /* line 109, src/assets/scss/components/_radios.scss */
    .duration-radio {
      padding: 15px; } }
  @media (max-width: 419px) {
    /* line 109, src/assets/scss/components/_radios.scss */
    .duration-radio {
      padding-top: 40px; } }
  /* line 131, src/assets/scss/components/_radios.scss */
  .duration-radio input, .duration-radio select.select-custom {
    display: none; }
  @media (max-width: 767.98px) {
    /* line 138, src/assets/scss/components/_radios.scss */
    .duration-radio label.duration-radio-label * {
      white-space: nowrap; } }
  @media (max-width: 575.98px) {
    /* line 135, src/assets/scss/components/_radios.scss */
    .duration-radio label.duration-radio-label {
      flex-direction: column;
      gap: 10px; }
      /* line 146, src/assets/scss/components/_radios.scss */
      .duration-radio label.duration-radio-label .subscription-info {
        justify-content: center; } }
  /* line 152, src/assets/scss/components/_radios.scss */
  .duration-radio input:checked ~ label, .duration-radio select.select-custom:checked ~ label {
    border-color: #0e2759; }
  /* line 156, src/assets/scss/components/_radios.scss */
  .duration-radio .price {
    font-size: 1rem;
    font-weight: 700;
    padding: 0 20px;
    white-space: nowrap; }
    @media (max-width: 1279.98px) {
      /* line 156, src/assets/scss/components/_radios.scss */
      .duration-radio .price {
        padding: 0 10px 0 20px;
        font-size: 0.8rem; } }
  /* line 166, src/assets/scss/components/_radios.scss */
  .duration-radio .button {
    height: 46px;
    font-size: 0.75rem;
    white-space: nowrap; }
    @media (max-width: 1279.98px) {
      /* line 166, src/assets/scss/components/_radios.scss */
      .duration-radio .button {
        font-size: 0.65rem;
        height: 40px; } }
    @media (max-width: 419px) {
      /* line 166, src/assets/scss/components/_radios.scss */
      .duration-radio .button {
        width: 100%; } }

/* line 181, src/assets/scss/components/_radios.scss */
.custom-radio input, .custom-radio select.select-custom {
  display: none; }

/* line 185, src/assets/scss/components/_radios.scss */
.custom-radio label {
  display: flex;
  align-items: center; }
  /* line 188, src/assets/scss/components/_radios.scss */
  .custom-radio label p {
    font-size: 0.7rem;
    margin-left: 15px;
    margin-bottom: 0; }
    /* line 192, src/assets/scss/components/_radios.scss */
    .custom-radio label p:nth-of-type(2) {
      font-size: 0.6rem;
      line-height: 1.666em; }
    @media (max-width: 419px) {
      /* line 188, src/assets/scss/components/_radios.scss */
      .custom-radio label p {
        font-size: 0.65rem;
        margin-left: 10px; } }
  /* line 201, src/assets/scss/components/_radios.scss */
  .custom-radio label .checkmark {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid rgba(14, 39, 89, 0.2);
    position: relative;
    flex-shrink: 0; }
    @media (max-width: 575.98px) {
      /* line 201, src/assets/scss/components/_radios.scss */
      .custom-radio label .checkmark {
        height: 24px;
        width: 24px; } }
    /* line 212, src/assets/scss/components/_radios.scss */
    .custom-radio label .checkmark:after {
      content: none;
      position: absolute; }

/* line 220, src/assets/scss/components/_radios.scss */
.custom-radio input:checked ~ label .checkmark:after, .custom-radio select.select-custom:checked ~ label .checkmark:after {
  content: "";
  background-color: #ff7a00;
  height: 16px;
  width: 16px;
  border-radius: 50%; }
  @media (max-width: 575.98px) {
    /* line 220, src/assets/scss/components/_radios.scss */
    .custom-radio input:checked ~ label .checkmark:after, .custom-radio select.select-custom:checked ~ label .checkmark:after {
      width: 14px;
      height: 14px; } }

/* line 237, src/assets/scss/components/_radios.scss */
.bank-radio input, .bank-radio select.select-custom {
  display: none; }

/* line 240, src/assets/scss/components/_radios.scss */
.bank-radio label {
  height: 90px;
  padding: 30px;
  border: 1px solid rgba(14, 39, 89, 0.2);
  border-radius: 2px; }
  @media (max-width: 992.98px) {
    /* line 240, src/assets/scss/components/_radios.scss */
    .bank-radio label {
      padding: 20px;
      height: 70px; } }
  /* line 251, src/assets/scss/components/_radios.scss */
  .bank-radio label img {
    object-fit: contain;
    max-width: 180px; }

/* line 256, src/assets/scss/components/_radios.scss */
.bank-radio input:checked ~ label, .bank-radio select.select-custom:checked ~ label {
  border-color: #0e2759;
  -webkit-box-shadow: 0px 0px 0px 1px #0e2759;
  box-shadow: 0px 0px 0px 1px #0e2759; }

/* line 2, src/assets/scss/components/_read-more.scss */
.text-with-read-more p:first-of-type {
  display: inline; }

/* line 6, src/assets/scss/components/_read-more.scss */
.text-with-read-more .read-more-input {
  display: none; }
  /* line 8, src/assets/scss/components/_read-more.scss */
  .text-with-read-more .read-more-input ~ p {
    display: none; }

/* line 13, src/assets/scss/components/_read-more.scss */
.text-with-read-more label.read-more-label {
  font-size: 0.8rem;
  color: #0e2759;
  line-height: 22px;
  font-weight: 400;
  text-decoration: underline;
  display: inline-block; }
  /* line 20, src/assets/scss/components/_read-more.scss */
  .text-with-read-more label.read-more-label:hover {
    text-decoration: none; }

/* line 26, src/assets/scss/components/_read-more.scss */
.text-with-read-more input:checked ~ label, .text-with-read-more select.select-custom:checked ~ label {
  display: none; }

/* line 29, src/assets/scss/components/_read-more.scss */
.text-with-read-more input:checked ~ p, .text-with-read-more select.select-custom:checked ~ p {
  display: block; }

/* line 1, src/assets/scss/components/_sections.scss */
section {
  padding-top: 30px;
  padding-bottom: 30px; }

/* line 6, src/assets/scss/components/_sections.scss */
.hero-section {
  padding-top: 40px;
  padding-bottom: 30px; }

@media (max-width: 767.98px) {
  /* line 11, src/assets/scss/components/_sections.scss */
  .hero-index-section {
    padding-top: 0; }
    /* line 14, src/assets/scss/components/_sections.scss */
    .hero-index-section .container {
      max-width: unset;
      padding: 0; } }

/* line 21, src/assets/scss/components/_sections.scss */
.auth-page, .user-page {
  padding: 40px 0 80px 0; }

/* line 25, src/assets/scss/components/_sections.scss */
.ad-page {
  padding: 40px 0 30px 0; }
  @media (max-width: 575.98px) {
    /* line 25, src/assets/scss/components/_sections.scss */
    .ad-page {
      padding: 40px 0 0 0; } }

/* line 1, src/assets/scss/components/_select.scss */
.card-select-wrap {
  position: relative;
  display: flex;
  align-items: center; }
  /* line 5, src/assets/scss/components/_select.scss */
  .card-select-wrap input, .card-select-wrap select.select-custom {
    display: none; }
  /* line 8, src/assets/scss/components/_select.scss */
  .card-select-wrap .input-wrap {
    border: 1px solid rgba(14, 39, 89, 0.2);
    border-radius: 2px;
    padding: 13px 20px 16px 20px;
    position: relative; }
    /* line 13, src/assets/scss/components/_select.scss */
    .card-select-wrap .input-wrap:after {
      content: "";
      position: absolute;
      background-image: url("../../assets/img/chevron-down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      right: 20px;
      height: 8px;
      width: 14px;
      transform: rotateX(0) translateY(-50%); }
    /* line 26, src/assets/scss/components/_select.scss */
    .card-select-wrap .input-wrap:not(.collapsed):after {
      transform: rotateX(180deg) translateY(50%); }
    /* line 30, src/assets/scss/components/_select.scss */
    .card-select-wrap .input-wrap img {
      max-width: 30px; }
    /* line 33, src/assets/scss/components/_select.scss */
    .card-select-wrap .input-wrap h6 {
      font-size: 0.7rem; }
    /* line 36, src/assets/scss/components/_select.scss */
    .card-select-wrap .input-wrap span {
      opacity: 0.4;
      font-size: 0.65rem; }
  /* line 41, src/assets/scss/components/_select.scss */
  .card-select-wrap .card-select-options {
    display: none;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    max-height: 160px;
    overflow-y: auto;
    flex-direction: column;
    border: 1px solid rgba(14, 39, 89, 0.2);
    border-top: none;
    z-index: 5; }
    /* line 54, src/assets/scss/components/_select.scss */
    .card-select-wrap .card-select-options .card-radio-option-wrap {
      padding: 0 19px;
      border-bottom: 1px solid rgba(14, 39, 89, 0.2); }
      /* line 57, src/assets/scss/components/_select.scss */
      .card-select-wrap .card-select-options .card-radio-option-wrap:last-of-type {
        border-bottom: none; }
      /* line 60, src/assets/scss/components/_select.scss */
      .card-select-wrap .card-select-options .card-radio-option-wrap input, .card-select-wrap .card-select-options .card-radio-option-wrap select.select-custom {
        display: none; }
      /* line 63, src/assets/scss/components/_select.scss */
      .card-select-wrap .card-select-options .card-radio-option-wrap label {
        cursor: pointer;
        height: 50px; }
        /* line 66, src/assets/scss/components/_select.scss */
        .card-select-wrap .card-select-options .card-radio-option-wrap label img {
          width: 21px;
          flex-shrink: 0; }
        /* line 70, src/assets/scss/components/_select.scss */
        .card-select-wrap .card-select-options .card-radio-option-wrap label h6 {
          font-size: 0.7rem; }
        /* line 73, src/assets/scss/components/_select.scss */
        .card-select-wrap .card-select-options .card-radio-option-wrap label span {
          opacity: 0.4;
          font-size: 0.65rem; }
    /* line 79, src/assets/scss/components/_select.scss */
    .card-select-wrap .card-select-options.show {
      display: flex; }

/* line 86, src/assets/scss/components/_select.scss */
.input-color-wrap .select-input-wrap {
  width: 100%;
  position: relative; }
  /* line 89, src/assets/scss/components/_select.scss */
  .input-color-wrap .select-input-wrap .color-display {
    position: absolute;
    border-radius: 50%;
    left: 20px;
    top: 13px;
    height: 20px;
    width: 20px; }
  /* line 98, src/assets/scss/components/_select.scss */
  .input-color-wrap .select-input-wrap select {
    padding-left: 50px; }

/* line 1, src/assets/scss/components/_sidebarcart.scss */
.sidebarcart {
  flex-shrink: 0;
  width: 590px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column; }
  @media (max-width: 992.98px) {
    /* line 1, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart {
      width: 500px; } }
  @media (max-width: 767.98px) {
    /* line 1, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart {
      width: 100%; } }
  /* line 24, src/assets/scss/components/_sidebarcart.scss */
  .sidebarcart.show {
    transform: translateX(0); }
  /* line 28, src/assets/scss/components/_sidebarcart.scss */
  .sidebarcart .sidebarcart-top {
    border-bottom: 1px solid rgba(14, 39, 89, 0.2);
    padding: 20px 30px 20px 25px; }
    /* line 32, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .sidebarcart-top h3 {
      font-size: 1.2rem;
      line-height: 35px;
      font-family: 'PlayfairDisplay'; }
      @media (max-width: 360px) {
        /* line 32, src/assets/scss/components/_sidebarcart.scss */
        .sidebarcart .sidebarcart-top h3 {
          font-size: 1rem; } }
  /* line 42, src/assets/scss/components/_sidebarcart.scss */
  .sidebarcart .cart-item {
    padding: 25px 24px 40px 25px;
    position: relative; }
    @media (max-width: 575.98px) {
      /* line 42, src/assets/scss/components/_sidebarcart.scss */
      .sidebarcart .cart-item {
        padding: 20px 15px 30px 15px; } }
    /* line 48, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .cart-item h5 {
      font-size: 1rem;
      line-height: 1.25em;
      margin-bottom: 20px; }
      @media (max-width: 575.98px) {
        /* line 48, src/assets/scss/components/_sidebarcart.scss */
        .sidebarcart .cart-item h5 {
          font-size: 0.8rem;
          margin-right: 15px; } }
    /* line 57, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .cart-item img {
      max-height: 139px; }
      @media (max-width: 575.98px) {
        /* line 57, src/assets/scss/components/_sidebarcart.scss */
        .sidebarcart .cart-item img {
          max-height: 90px; } }
    /* line 64, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .cart-item h6 {
      font-family: "Open Sans";
      font-size: 0.7rem;
      font-weight: 600; }
      @media (max-width: 575.98px) {
        /* line 64, src/assets/scss/components/_sidebarcart.scss */
        .sidebarcart .cart-item h6 {
          font-size: 0.65rem; } }
    /* line 73, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .cart-item p {
      font-size: 0.7rem; }
    /* line 77, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .cart-item .price {
      color: #ff7a00;
      font-size: 0.7rem;
      font-weight: 700;
      line-height: 1.5625em;
      white-space: nowrap; }
      @media (max-width: 419px) {
        /* line 77, src/assets/scss/components/_sidebarcart.scss */
        .sidebarcart .cart-item .price {
          position: absolute;
          bottom: 30px;
          left: 15px; } }
    /* line 89, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .cart-item .text-blue {
      font-size: 0.75rem;
      white-space: nowrap; }
  /* line 95, src/assets/scss/components/_sidebarcart.scss */
  .sidebarcart .sidebarcart-bottom {
    margin-top: auto;
    padding: 25px;
    border-top: 1px solid rgba(14, 39, 89, 0.2); }
    /* line 99, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .sidebarcart-bottom p {
      font-size: 0.8rem;
      line-height: 1.5625em; }
      /* line 102, src/assets/scss/components/_sidebarcart.scss */
      .sidebarcart .sidebarcart-bottom p:last-of-type {
        font-weight: 700; }
    /* line 106, src/assets/scss/components/_sidebarcart.scss */
    .sidebarcart .sidebarcart-bottom button {
      margin-top: 25px; }

/* line 2, src/assets/scss/components/_switch.scss */
.switch {
  position: relative;
  display: inline-block;
  height: 36px;
  width: 62px; }

/* line 9, src/assets/scss/components/_switch.scss */
.switch input, .switch select.select-custom {
  opacity: 0;
  width: 0;
  height: 0; }

/* line 15, src/assets/scss/components/_switch.scss */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 18px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.25); }

/* line 29, src/assets/scss/components/_switch.scss */
.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2); }

/* line 43, src/assets/scss/components/_switch.scss */
input:checked + .slider, select.select-custom:checked + .slider {
  background-color: #ff7a00; }

/* line 47, src/assets/scss/components/_switch.scss */
input:focus + .slider, select.select-custom:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

/* line 51, src/assets/scss/components/_switch.scss */
input:checked + .slider:before, select.select-custom:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

/* line 61, src/assets/scss/components/_switch.scss */
.slider.round:before {
  border-radius: 50%; }

/* line 3, src/assets/scss/components/_tabs.scss */
.nav-tabs {
  border: none; }
  /* line 5, src/assets/scss/components/_tabs.scss */
  .nav-tabs a.nav-link {
    font-size: 0.75rem;
    color: #000000;
    padding: 17px 0;
    border: none;
    background-color: transparent;
    margin-right: 40px; }
    @media (max-width: 992.98px) {
      /* line 5, src/assets/scss/components/_tabs.scss */
      .nav-tabs a.nav-link {
        margin-right: 0;
        font-size: 0.7rem;
        padding: 13px 0; } }
    @media (max-width: 767.98px) {
      /* line 5, src/assets/scss/components/_tabs.scss */
      .nav-tabs a.nav-link {
        font-size: 0.65rem; } }
    @media (max-width: 767.98px) {
      /* line 5, src/assets/scss/components/_tabs.scss */
      .nav-tabs a.nav-link {
        text-align: center;
        border-bottom: 1px solid rgba(14, 39, 89, 0.2); } }
    /* line 24, src/assets/scss/components/_tabs.scss */
    .nav-tabs a.nav-link.active {
      font-weight: 600;
      color: inherit;
      background-color: transparent;
      border-bottom: 2px solid #0e2759; }

/* line 34, src/assets/scss/components/_tabs.scss */
.nav-tabs.nav-tabs-accent {
  width: 100%;
  border-radius: 2px;
  height: 46px; }
  /* line 38, src/assets/scss/components/_tabs.scss */
  .nav-tabs.nav-tabs-accent .nav-link {
    flex: 1;
    height: 100%;
    font-size: 0.75rem;
    border-radius: 0;
    border: 1px solid rgba(14, 39, 89, 0.2); }
    /* line 44, src/assets/scss/components/_tabs.scss */
    .nav-tabs.nav-tabs-accent .nav-link.active {
      background-color: #ff7a00;
      border-color: #ff7a00;
      color: #ffffff; }
  /* line 50, src/assets/scss/components/_tabs.scss */
  .nav-tabs.nav-tabs-accent#add-payment-type-tab {
    height: unset; }
    /* line 53, src/assets/scss/components/_tabs.scss */
    .nav-tabs.nav-tabs-accent#add-payment-type-tab button {
      height: unset;
      padding: 12px 15px 14px 15px; }

/* line 62, src/assets/scss/components/_tabs.scss */
.tabs-swiper nav {
  flex-wrap: nowrap;
  align-items: stretch;
  height: 55px; }
  @media (max-width: 992.98px) {
    /* line 62, src/assets/scss/components/_tabs.scss */
    .tabs-swiper nav {
      height: 75px; } }
  @media (max-width: 575.98px) {
    /* line 62, src/assets/scss/components/_tabs.scss */
    .tabs-swiper nav {
      height: 50px;
      padding: 0 45px 0 15px; } }
  /* line 73, src/assets/scss/components/_tabs.scss */
  .tabs-swiper nav .swiper-slide.nav-link {
    text-align: center;
    height: 100%; }
    @media (min-width: 993px) {
      /* line 73, src/assets/scss/components/_tabs.scss */
      .tabs-swiper nav .swiper-slide.nav-link {
        width: fit-content !important;
        white-space: nowrap; } }
    @media (max-width: 575.98px) {
      /* line 73, src/assets/scss/components/_tabs.scss */
      .tabs-swiper nav .swiper-slide.nav-link {
        white-space: nowrap;
        width: fit-content !important; } }

/* line 1, src/assets/scss/components/_typography.scss */
p, a, span, label {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.333em; }

/* line 6, src/assets/scss/components/_typography.scss */
* {
  color: #000000; }

/* line 10, src/assets/scss/components/_typography.scss */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.333em;
  font-weight: 500; }
  /* line 15, src/assets/scss/components/_typography.scss */
  h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
    font-weight: 700; }

/* line 20, src/assets/scss/components/_typography.scss */
h1 {
  font-family: "PlayfairDisplay"; }

/* line 24, src/assets/scss/components/_typography.scss */
.playfair {
  font-family: "PlayfairDisplay"; }

/* line 28, src/assets/scss/components/_typography.scss */
h2 {
  font-family: "PlayfairDisplay";
  font-size: 1.8rem;
  line-height: 1.25em; }
  @media (max-width: 575.98px) {
    /* line 28, src/assets/scss/components/_typography.scss */
    h2 {
      font-size: 1.4rem; } }

/* line 37, src/assets/scss/components/_typography.scss */
h3 {
  font-size: 1.2rem;
  line-height: 1.458em; }

/* line 42, src/assets/scss/components/_typography.scss */
p {
  font-size: 0.8rem;
  line-height: 1.375em; }
  @media (max-width: 575.98px) {
    /* line 42, src/assets/scss/components/_typography.scss */
    p {
      font-size: 0.7rem; } }

/* line 50, src/assets/scss/components/_typography.scss */
h6.section-label {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5625em; }

/* line 56, src/assets/scss/components/_typography.scss */
label {
  font-weight: 600;
  font-size: 0.7rem; }

/* line 1, src/assets/scss/pages/_404.scss */
.not-found-section {
  position: relative;
  min-height: calc(100vh - 168px);
  display: flex;
  align-items: center;
  padding-top: 50px; }
  @media (max-width: 992.98px) {
    /* line 1, src/assets/scss/pages/_404.scss */
    .not-found-section {
      min-height: calc(100vh - 178px); } }
  @media (max-width: 575.98px) {
    /* line 1, src/assets/scss/pages/_404.scss */
    .not-found-section {
      padding-bottom: 250px; } }
  /* line 13, src/assets/scss/pages/_404.scss */
  .not-found-section p {
    font-size: 0.8rem;
    margin-top: 20px;
    text-align: center; }
  /* line 18, src/assets/scss/pages/_404.scss */
  .not-found-section button {
    margin-top: 80px;
    width: 66%; }
    @media (max-width: 767.98px) {
      /* line 18, src/assets/scss/pages/_404.scss */
      .not-found-section button {
        width: 80%; } }
    @media (max-width: 575.98px) {
      /* line 18, src/assets/scss/pages/_404.scss */
      .not-found-section button {
        margin-top: 40px; } }
  /* line 28, src/assets/scss/pages/_404.scss */
  .not-found-section img {
    position: absolute;
    right: 66%;
    bottom: 0;
    height: 100%;
    z-index: -1;
    object-fit: contain;
    max-height: 90%;
    object-position: bottom; }
    @media (max-width: 767.98px) {
      /* line 28, src/assets/scss/pages/_404.scss */
      .not-found-section img {
        height: 70%; } }
    @media (max-width: 575.98px) {
      /* line 28, src/assets/scss/pages/_404.scss */
      .not-found-section img {
        max-height: 250px;
        bottom: 0;
        right: 55%;
        transform: translateX(50%); } }

/* line 2, src/assets/scss/pages/_faq.scss */
.accordion.faq-accordion .accordion-item {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0; }

/* line 8, src/assets/scss/pages/_faq.scss */
.accordion.faq-accordion button {
  padding: 25px 5px 20px 0;
  align-items: center; }
  /* line 11, src/assets/scss/pages/_faq.scss */
  .accordion.faq-accordion button h3 {
    font-family: "PlayfairDisplay"; }
    @media (max-width: 575.98px) {
      /* line 11, src/assets/scss/pages/_faq.scss */
      .accordion.faq-accordion button h3 {
        font-size: 1rem; } }
  /* line 17, src/assets/scss/pages/_faq.scss */
  .accordion.faq-accordion button img {
    transition: all 0.2s ease-in-out;
    transform: rotate(270deg); }
  /* line 22, src/assets/scss/pages/_faq.scss */
  .accordion.faq-accordion button.collapsed img {
    transform: rotate(90deg); }

/* line 28, src/assets/scss/pages/_faq.scss */
.accordion.faq-accordion .accordion-collapse p {
  font-size: 0.8rem;
  line-height: 1.375em;
  margin-bottom: 25px;
  padding-right: 20px; }

/* line 1, src/assets/scss/pages/_new_ad.scss */
.wrapper-block {
  padding: 20px 25px 25px 25px;
  border: 1px solid rgba(14, 39, 89, 0.2);
  background-color: #ffffff; }
  @media (max-width: 419px) {
    /* line 1, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block {
      margin-left: -15px;
      margin-right: -15px;
      padding: 20px 15px 30px 15px;
      border: none; } }
  /* line 12, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.35em;
    margin-bottom: 25px; }
  /* line 19, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block p {
    font-size: 0.7rem;
    margin-bottom: 20px; }
    /* line 22, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block p:last-of-type {
      margin-bottom: 0; }
    /* line 25, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block p strong {
      font-weight: 600; }
  @media (max-width: 992.98px) {
    /* line 30, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block.ad-info-block {
      padding: 20px 15px 25px 15px; } }
  @media (max-width: 767.98px) {
    /* line 30, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block.ad-info-block {
      padding: 20px 25px 25px 25px; } }
  /* line 37, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block.ad-info-block > .d-flex {
    margin-bottom: 10px; }
  /* line 41, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block.ad-info-block h6, .wrapper-block.ad-info-block h5 {
    font-size: 0.75rem;
    line-height: 1.66em;
    font-weight: 400; }
    @media (max-width: 992.98px) {
      /* line 41, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block.ad-info-block h6, .wrapper-block.ad-info-block h5 {
        font-size: 0.7rem; } }
    @media (max-width: 767.98px) {
      /* line 41, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block.ad-info-block h6, .wrapper-block.ad-info-block h5 {
        font-size: 0.75rem; } }
    @media (max-width: 575.98px) {
      /* line 41, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block.ad-info-block h6, .wrapper-block.ad-info-block h5 {
        font-size: 0.7rem; } }
  /* line 55, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block.ad-info-block .totals {
    margin-bottom: 20px; }
    /* line 57, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block.ad-info-block .totals h6, .wrapper-block.ad-info-block .totals h5 {
      font-weight: 700; }
  /* line 62, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block .info {
    background-color: rgba(255, 122, 0, 0.3);
    padding: 18px 20px 15px 20px;
    border-radius: 2px; }
    @media (max-width: 575.98px) {
      /* line 62, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block .info {
        padding: 15px 15px 12px 15px; } }
    /* line 69, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block .info p {
      font-size: 0.7rem; }
    /* line 72, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block .info h6 {
      font-size: 0.7rem;
      font-weight: 600;
      margin-bottom: 11px; }
    /* line 77, src/assets/scss/pages/_new_ad.scss */
    .wrapper-block .info.info-blue {
      background-color: rgba(14, 39, 89, 0.2); }
  /* line 82, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block.add-ad-by-phone-block {
    background-color: rgba(14, 39, 89, 0.05);
    border-color: transparent; }
  /* line 87, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block .select-wrap {
    width: 50%;
    margin-bottom: 30px; }
    @media (max-width: 992.98px) {
      /* line 87, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block .select-wrap {
        width: 100%; } }
    @media (max-width: 767.98px) {
      /* line 87, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block .select-wrap {
        width: 50%; } }
    @media (max-width: 575.98px) {
      /* line 87, src/assets/scss/pages/_new_ad.scss */
      .wrapper-block .select-wrap {
        width: 100%; } }
  /* line 101, src/assets/scss/pages/_new_ad.scss */
  .wrapper-block .ad-input-wrap input, .wrapper-block .ad-input-wrap select.select-custom {
    border: 2px solid #ff7a00;
    border-radius: 2px; }

/* line 108, src/assets/scss/pages/_new_ad.scss */
.input-wrap.radio-input-wrap {
  display: flex;
  gap: 15px 0;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  /* line 113, src/assets/scss/pages/_new_ad.scss */
  .input-wrap.radio-input-wrap > label {
    width: 100%;
    display: block;
    margin-bottom: 0; }
  /* line 118, src/assets/scss/pages/_new_ad.scss */
  .input-wrap.radio-input-wrap .custom-radio {
    width: 25%;
    padding-right: 20px; }
    @media (max-width: 1279.98px) {
      /* line 118, src/assets/scss/pages/_new_ad.scss */
      .input-wrap.radio-input-wrap .custom-radio {
        width: 33.33%; } }
    @media (max-width: 992.98px) {
      /* line 118, src/assets/scss/pages/_new_ad.scss */
      .input-wrap.radio-input-wrap .custom-radio {
        width: 50%; } }
    @media (max-width: 767.98px) {
      /* line 118, src/assets/scss/pages/_new_ad.scss */
      .input-wrap.radio-input-wrap .custom-radio {
        width: 33.333%; } }
    @media (max-width: 575.98px) {
      /* line 118, src/assets/scss/pages/_new_ad.scss */
      .input-wrap.radio-input-wrap .custom-radio {
        width: 50%; } }

@media (max-width: 767.98px) {
  /* line 2, src/assets/scss/pages/_order.scss */
  .order-page h3 {
    font-size: 1rem; } }

@media (max-width: 575.98px) {
  /* line 2, src/assets/scss/pages/_order.scss */
  .order-page h3 {
    padding-right: 20px; } }

/* line 10, src/assets/scss/pages/_order.scss */
.order-page .wrapper-block {
  padding: 20px 20px 30px 25px; }

/* line 14, src/assets/scss/pages/_order.scss */
.order-block {
  position: relative;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-right: 30px;
  border-bottom: 1px solid rgba(14, 39, 89, 0.2); }
  @media (max-width: 575.98px) {
    /* line 14, src/assets/scss/pages/_order.scss */
    .order-block {
      padding-right: 10px; } }
  @media (max-width: 575.98px) {
    /* line 23, src/assets/scss/pages/_order.scss */
    .order-block .wrap-mobile {
      margin-top: 30px; } }
  /* line 28, src/assets/scss/pages/_order.scss */
  .order-block .img-wrap {
    width: 100px;
    height: 140px;
    grid-row: 1 / 3; }
    @media (max-width: 575.98px) {
      /* line 28, src/assets/scss/pages/_order.scss */
      .order-block .img-wrap {
        grid-row: 1 / 2;
        width: 90px;
        height: 120px; } }
  /* line 38, src/assets/scss/pages/_order.scss */
  .order-block .price {
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 35px;
    white-space: nowrap; }
    @media (max-width: 575.98px) {
      /* line 38, src/assets/scss/pages/_order.scss */
      .order-block .price {
        font-size: 14px; } }
  /* line 48, src/assets/scss/pages/_order.scss */
  .order-block .delete-order {
    position: absolute;
    top: 42px;
    right: 0; }
  /* line 54, src/assets/scss/pages/_order.scss */
  .order-block .input-label span {
    font-weight: 400; }
  /* line 58, src/assets/scss/pages/_order.scss */
  .order-block .order-start-select-wrap {
    margin-bottom: 20px;
    grid-row: 2/3; }
    @media (max-width: 992.98px) {
      /* line 58, src/assets/scss/pages/_order.scss */
      .order-block .order-start-select-wrap {
        gap: 10px; } }
    /* line 64, src/assets/scss/pages/_order.scss */
    .order-block .order-start-select-wrap a {
      white-space: nowrap; }
    /* line 67, src/assets/scss/pages/_order.scss */
    .order-block .order-start-select-wrap a {
      font-size: 0.75rem;
      color: #0e2759;
      align-items: center; }
      /* line 71, src/assets/scss/pages/_order.scss */
      .order-block .order-start-select-wrap a img {
        width: 16px; }
  /* line 76, src/assets/scss/pages/_order.scss */
  .order-block .display-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr; }
    /* line 81, src/assets/scss/pages/_order.scss */
    .order-block .display-grid .order-block-title {
      margin-left: 40px; }
      @media (max-width: 1279.98px) {
        /* line 81, src/assets/scss/pages/_order.scss */
        .order-block .display-grid .order-block-title {
          margin-left: 20px; } }
      @media (max-width: 575.98px) {
        /* line 81, src/assets/scss/pages/_order.scss */
        .order-block .display-grid .order-block-title {
          margin-left: 10px; } }
    /* line 91, src/assets/scss/pages/_order.scss */
    .order-block .display-grid .subscription-selections {
      grid-column: 2/3;
      margin-left: 40px; }
      @media (max-width: 1279.98px) {
        /* line 91, src/assets/scss/pages/_order.scss */
        .order-block .display-grid .subscription-selections {
          margin-left: 20px; } }
      @media (max-width: 575.98px) {
        /* line 91, src/assets/scss/pages/_order.scss */
        .order-block .display-grid .subscription-selections {
          grid-column: 1/3;
          margin-left: 0;
          margin-top: 10px; } }

/* line 2, src/assets/scss/pages/_payment.scss */
.payment-card-form > a {
  float: right;
  display: inline-block;
  width: 50%; }

/* line 8, src/assets/scss/pages/_payment.scss */
.payment-card-form > select {
  float: left;
  display: inline-block;
  width: 50%; }

/* line 15, src/assets/scss/pages/_payment.scss */
#discount-input-accordion {
  border: 1px solid rgba(14, 39, 89, 0.2);
  border-radius: 2px; }
  /* line 18, src/assets/scss/pages/_payment.scss */
  #discount-input-accordion .discount-input-button {
    height: 46px;
    padding: 0 20px; }
    /* line 21, src/assets/scss/pages/_payment.scss */
    #discount-input-accordion .discount-input-button img {
      transition: all 0.2s ease-in-out; }
    /* line 24, src/assets/scss/pages/_payment.scss */
    #discount-input-accordion .discount-input-button span {
      font-size: 0.75rem; }
    /* line 28, src/assets/scss/pages/_payment.scss */
    #discount-input-accordion .discount-input-button:not(.collapsed) img:nth-of-type(2) {
      transform: rotate(90deg); }
  /* line 33, src/assets/scss/pages/_payment.scss */
  #discount-input-accordion .input-wrap {
    border-radius: 0 0 2px 2px;
    padding-right: 20px;
    border-top: 1px solid rgba(14, 39, 89, 0.2); }
    /* line 37, src/assets/scss/pages/_payment.scss */
    #discount-input-accordion .input-wrap input, #discount-input-accordion .input-wrap select.select-custom {
      border: none;
      padding-left: 20px;
      width: 100%;
      background-color: transparent !important; }
      /* line 42, src/assets/scss/pages/_payment.scss */
      #discount-input-accordion .input-wrap input::placeholder, #discount-input-accordion .input-wrap select.select-custom::placeholder {
        color: #000000;
        opacity: 1; }
    /* line 48, src/assets/scss/pages/_payment.scss */
    #discount-input-accordion .input-wrap button {
      background-color: #000000;
      color: #ffffff;
      text-transform: uppercase;
      height: 28px;
      padding: 0 10px;
      border-radius: 2px;
      font-size: 0.6rem; }

/* line 1, src/assets/scss/pages/_product-inner.scss */
.product-radios-row {
  gap: 10px; }
  /* line 3, src/assets/scss/pages/_product-inner.scss */
  .product-radios-row .product-select-radio {
    flex: 1; }

/* line 8, src/assets/scss/pages/_product-inner.scss */
.duration-radios-column {
  flex-direction: column; }

/* line 12, src/assets/scss/pages/_product-inner.scss */
.ipad-frame {
  width: 90px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0; }
  @media (max-width: 575.98px) {
    /* line 12, src/assets/scss/pages/_product-inner.scss */
    .ipad-frame {
      width: 70px;
      grid-row: 1/4; } }
  @media (max-width: 419px) {
    /* line 12, src/assets/scss/pages/_product-inner.scss */
    .ipad-frame {
      margin: 0; } }
  /* line 26, src/assets/scss/pages/_product-inner.scss */
  .ipad-frame img:first-of-type {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2; }
  /* line 33, src/assets/scss/pages/_product-inner.scss */
  .ipad-frame img:last-of-type {
    position: absolute;
    top: 2px;
    bottom: 4px;
    z-index: 1;
    left: 2px;
    right: 5px;
    width: 96% !important; }

/* line 44, src/assets/scss/pages/_product-inner.scss */
.product-select-combo-wrap {
  position: relative;
  display: flex;
  align-items: baseline;
  width: 140px;
  margin: 0 auto; }
  @media (max-width: 1279.98px) {
    /* line 44, src/assets/scss/pages/_product-inner.scss */
    .product-select-combo-wrap {
      width: 120px; } }
  @media (max-width: 992.98px) {
    /* line 44, src/assets/scss/pages/_product-inner.scss */
    .product-select-combo-wrap {
      width: 100%; } }
  @media (max-width: 575.98px) {
    /* line 44, src/assets/scss/pages/_product-inner.scss */
    .product-select-combo-wrap {
      grid-row: 1/4; } }
  /* line 59, src/assets/scss/pages/_product-inner.scss */
  .product-select-combo-wrap > img {
    object-fit: contain;
    object-position: left; }
  /* line 63, src/assets/scss/pages/_product-inner.scss */
  .product-select-combo-wrap .ipad-frame {
    width: 67px;
    position: absolute;
    right: 0;
    bottom: 0; }
  /* line 70, src/assets/scss/pages/_product-inner.scss */
  .product-select-combo-wrap .img-wrap {
    margin-bottom: 0; }

/* line 1, src/assets/scss/pages/_text-page.scss */
.text-page {
  padding-top: 40px; }
  /* line 3, src/assets/scss/pages/_text-page.scss */
  .text-page h2 {
    margin-bottom: 20px;
    font-weight: 400; }
  /* line 7, src/assets/scss/pages/_text-page.scss */
  .text-page h3 {
    margin-bottom: 20px;
    font-family: "PlayfairDisplay";
    line-height: 1.6666em; }

/* line 14, src/assets/scss/pages/_text-page.scss */
.text-block {
  margin-bottom: 40px; }
  /* line 17, src/assets/scss/pages/_text-page.scss */
  .text-block p, .text-block li {
    font-size: 0.8rem;
    line-height: 1.375em; }
    @media (max-width: 575.98px) {
      /* line 17, src/assets/scss/pages/_text-page.scss */
      .text-block p, .text-block li {
        font-size: 0.7rem; } }
  /* line 25, src/assets/scss/pages/_text-page.scss */
  .text-block ol {
    list-style: decimal;
    margin-bottom: 20px;
    padding-left: 20px;
    margin-top: 20px; }
    /* line 30, src/assets/scss/pages/_text-page.scss */
    .text-block ol:last-of-type {
      margin-bottom: 0; }
  /* line 35, src/assets/scss/pages/_text-page.scss */
  .text-block p {
    margin-bottom: 20px; }
    /* line 37, src/assets/scss/pages/_text-page.scss */
    .text-block p:last-of-type {
      margin-bottom: 0; }

/* line 1, src/assets/scss/pages/_user-dashboard.scss */
#user-nav-tabContent, #user-empty-nav-tabContent {
  padding-top: 60px; }
  /* line 3, src/assets/scss/pages/_user-dashboard.scss */
  #user-nav-tabContent *, #user-empty-nav-tabContent * {
    font-family: "Open Sans"; }
  /* line 6, src/assets/scss/pages/_user-dashboard.scss */
  #user-nav-tabContent h3, #user-empty-nav-tabContent h3 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 35px; }
  /* line 11, src/assets/scss/pages/_user-dashboard.scss */
  #user-nav-tabContent .tab-content-title, #user-empty-nav-tabContent .tab-content-title {
    margin-bottom: 40px; }
    /* line 13, src/assets/scss/pages/_user-dashboard.scss */
    #user-nav-tabContent .tab-content-title .button, #user-empty-nav-tabContent .tab-content-title .button {
      border: none;
      background-color: rgba(14, 39, 89, 0.1);
      color: #000000;
      height: 46px; }
      /* line 18, src/assets/scss/pages/_user-dashboard.scss */
      #user-nav-tabContent .tab-content-title .button:hover, #user-empty-nav-tabContent .tab-content-title .button:hover {
        background-color: rgba(14, 39, 89, 0.2); }

/* line 25, src/assets/scss/pages/_user-dashboard.scss */
.user-dashboard-row {
  padding: 29px 20px 35px 0;
  border-top: 1px solid rgba(14, 39, 89, 0.2); }
  @media (max-width: 575.98px) {
    /* line 25, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row {
      padding: 25px 0; } }
  /* line 31, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row:last-of-type {
    border-bottom: 1px solid rgba(14, 39, 89, 0.2); }
  /* line 35, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row h5 {
    font-weight: 600;
    font-size: 0.75rem; }
  /* line 39, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row h6 {
    font-weight: 400;
    font-size: 0.7rem;
    margin-top: 5px; }
  /* line 44, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row p {
    font-size: 0.7rem;
    opacity: 0.6;
    margin-top: 20px; }
  /* line 49, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row.payment-row {
    padding: 33px 20px 32px 0; }
  /* line 52, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row.user-settings-row {
    padding: 28px 28px 27px 0; }
    @media (max-width: 575.98px) {
      /* line 52, src/assets/scss/pages/_user-dashboard.scss */
      .user-dashboard-row.user-settings-row {
        padding: 22px 0; } }
    /* line 58, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row.user-settings-row a {
      font-size: 0.75rem;
      line-height: 1.6666em;
      text-align: right;
      color: #0e2759;
      margin-left: 15px; }
      /* line 65, src/assets/scss/pages/_user-dashboard.scss */
      .user-dashboard-row.user-settings-row a img {
        margin-left: 30px; }
  /* line 70, src/assets/scss/pages/_user-dashboard.scss */
  .user-dashboard-row.user-order-row {
    padding: 27px 28px 28px 0; }
    @media (max-width: 575.98px) {
      /* line 70, src/assets/scss/pages/_user-dashboard.scss */
      .user-dashboard-row.user-order-row {
        padding: 22px 0px 22px 0; } }
    /* line 75, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row.user-order-row .order-title {
      flex: 1; }
    /* line 78, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row.user-order-row .order-info-elems {
      flex: 1; }
    /* line 81, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row.user-order-row ul {
      margin-top: 5px; }
      /* line 83, src/assets/scss/pages/_user-dashboard.scss */
      .user-dashboard-row.user-order-row ul li {
        font-size: 0.7rem;
        line-height: 1.43em; }
        @media (max-width: 575.98px) {
          /* line 83, src/assets/scss/pages/_user-dashboard.scss */
          .user-dashboard-row.user-order-row ul li {
            font-size: 0.60rem; } }
    /* line 91, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row.user-order-row .date {
      opacity: 0.6;
      font-size: 0.7rem;
      line-height: 1.666em;
      white-space: nowrap;
      padding: 0 15px; }
      @media (max-width: 575.98px) {
        /* line 91, src/assets/scss/pages/_user-dashboard.scss */
        .user-dashboard-row.user-order-row .date {
          font-size: 0.60rem; } }
    /* line 102, src/assets/scss/pages/_user-dashboard.scss */
    .user-dashboard-row.user-order-row .price {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.666em;
      margin-right: 60px;
      white-space: nowrap; }
      @media (max-width: 575.98px) {
        /* line 102, src/assets/scss/pages/_user-dashboard.scss */
        .user-dashboard-row.user-order-row .price {
          margin-right: 20px;
          font-size: 0.60rem; } }

/* line 116, src/assets/scss/pages/_user-dashboard.scss */
.user-info-row {
  max-width: 525px; }
  /* line 119, src/assets/scss/pages/_user-dashboard.scss */
  .user-info-row img {
    height: 68px;
    width: 68px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid #ffffff;
    object-fit: cover;
    object-position: center;
    margin-right: 34px; }
  /* line 129, src/assets/scss/pages/_user-dashboard.scss */
  .user-info-row h5 {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.375em;
    margin-bottom: 10px; }
  /* line 135, src/assets/scss/pages/_user-dashboard.scss */
  .user-info-row p {
    font-size: 0.7rem; }

/* line 140, src/assets/scss/pages/_user-dashboard.scss */
.subscriptions-row {
  max-width: 625px;
  margin-top: 40px; }
  /* line 143, src/assets/scss/pages/_user-dashboard.scss */
  .subscriptions-row > .d-flex {
    gap: 20px; }

/* line 1, src/assets/scss/blocks/_landing-swiper.scss */
.landing-swiper {
  height: 300px; }
  @media (max-width: 1279.98px) {
    /* line 1, src/assets/scss/blocks/_landing-swiper.scss */
    .landing-swiper {
      height: 250px; } }
  @media (max-width: 992.98px) {
    /* line 1, src/assets/scss/blocks/_landing-swiper.scss */
    .landing-swiper {
      height: 200px; } }
  /* line 10, src/assets/scss/blocks/_landing-swiper.scss */
  .landing-swiper .swiper-slide div {
    width: 100%;
    height: 100%;
    background: url("../../assets/img/landing-banner.svg") no-repeat center;
    background-size: cover; }
    @media (max-width: 992.98px) {
      /* line 10, src/assets/scss/blocks/_landing-swiper.scss */
      .landing-swiper .swiper-slide div {
        background-position: 40%; } }
    @media (max-width: 767.98px) {
      /* line 10, src/assets/scss/blocks/_landing-swiper.scss */
      .landing-swiper .swiper-slide div {
        background-position: 80%; } }
    /* line 23, src/assets/scss/blocks/_landing-swiper.scss */
    .landing-swiper .swiper-slide div h1 {
      font-size: 2.1rem;
      font-weight: 900;
      color: #ffffff; }
      @media (max-width: 992.98px) {
        /* line 23, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h1 {
          font-size: 1.5rem; } }
      @media (max-width: 767.98px) {
        /* line 23, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h1 {
          max-width: 65%; } }
      @media (max-width: 575.98px) {
        /* line 23, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h1 {
          max-width: 50%;
          font-size: 1.2rem; } }
    /* line 39, src/assets/scss/blocks/_landing-swiper.scss */
    .landing-swiper .swiper-slide div h2 {
      font-family: 'Montserrat Alternates', sans-serif;
      color: #ffffff;
      margin-left: 50px;
      letter-spacing: 2px;
      text-transform: uppercase; }
      @media (min-width: 1280px) {
        /* line 39, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h2 {
          font-size: 3rem;
          margin-left: 120px; } }
      @media (min-width: 993px) {
        /* line 39, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h2 {
          font-size: 2.8rem;
          margin-left: 100px; } }
      @media (max-width: 767.98px) {
        /* line 39, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h2 {
          margin-left: 0;
          margin-top: 10px; } }
      @media (max-width: 575.98px) {
        /* line 39, src/assets/scss/blocks/_landing-swiper.scss */
        .landing-swiper .swiper-slide div h2 {
          max-width: 50%;
          font-size: 1rem; } }
  /* line 66, src/assets/scss/blocks/_landing-swiper.scss */
  .landing-swiper .swiper-pagination-bullet {
    height: 8px;
    width: 8px;
    border: 1px solid #ffffff;
    background-color: transparent;
    opacity: 1; }
    /* line 72, src/assets/scss/blocks/_landing-swiper.scss */
    .landing-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #ffffff; }

/* line 1, src/assets/scss/blocks/_product-inner-swiper.scss */
.product-inner-swiper {
  height: 123px; }
  /* line 5, src/assets/scss/blocks/_product-inner-swiper.scss */
  .product-inner-swiper .swiper-slide img {
    width: 100%;
    object-fit: contain; }
    @media (max-width: 992.98px) {
      /* line 5, src/assets/scss/blocks/_product-inner-swiper.scss */
      .product-inner-swiper .swiper-slide img {
        height: 100%; } }

/* line 15, src/assets/scss/blocks/_product-inner-swiper.scss */
.img-placeholder {
  position: relative;
  padding-right: 9px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  line-height: 0; }
  @media (max-width: 992.98px) {
    /* line 15, src/assets/scss/blocks/_product-inner-swiper.scss */
    .img-placeholder {
      width: fit-content;
      margin: 0 auto 20px auto; } }
  /* line 25, src/assets/scss/blocks/_product-inner-swiper.scss */
  .img-placeholder:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    left: 10px;
    bottom: 0;
    background-color: rgba(14, 39, 89, 0.1); }
  /* line 34, src/assets/scss/blocks/_product-inner-swiper.scss */
  .img-placeholder img {
    width: 100%;
    transition: all 0.2s ease-in-out;
    opacity: 1; }
    @media (max-width: 992.98px) {
      /* line 34, src/assets/scss/blocks/_product-inner-swiper.scss */
      .img-placeholder img {
        max-height: 400px; } }
    /* line 41, src/assets/scss/blocks/_product-inner-swiper.scss */
    .img-placeholder img.transitioning {
      transition: all 0.2s ease-in-out;
      opacity: 0.3; }

@media (min-width: 768px) {
  /* line 7, src/assets/scss/blocks/_product-section.scss */
  .product-section.product-section-2 .row:nth-of-type(2) > div:nth-of-type(2) .product-card {
    margin-left: auto; } }

/* line 16, src/assets/scss/blocks/_product-section.scss */
.product-section.product-section-3 .product-card {
  max-width: 320px; }
  @media (max-width: 575.98px) {
    /* line 16, src/assets/scss/blocks/_product-section.scss */
    .product-section.product-section-3 .product-card {
      max-width: 250px; } }
  /* line 22, src/assets/scss/blocks/_product-section.scss */
  .product-section.product-section-3 .product-card .img-wrap {
    height: 430px; }
    @media (max-width: 1279.98px) {
      /* line 22, src/assets/scss/blocks/_product-section.scss */
      .product-section.product-section-3 .product-card .img-wrap {
        height: 400px; } }
    @media (max-width: 992.98px) {
      /* line 22, src/assets/scss/blocks/_product-section.scss */
      .product-section.product-section-3 .product-card .img-wrap {
        height: 300px; } }
    @media (max-width: 767.98px) {
      /* line 22, src/assets/scss/blocks/_product-section.scss */
      .product-section.product-section-3 .product-card .img-wrap {
        height: 340px; } }

/* line 38, src/assets/scss/blocks/_product-section.scss */
.product-section.product-section-4 {
  padding-bottom: 80px; }
  @media (max-width: 575.98px) {
    /* line 38, src/assets/scss/blocks/_product-section.scss */
    .product-section.product-section-4 {
      padding-bottom: 40px; } }
  /* line 43, src/assets/scss/blocks/_product-section.scss */
  .product-section.product-section-4 .product-card {
    max-width: 285px; }
    @media (max-width: 575.98px) {
      /* line 43, src/assets/scss/blocks/_product-section.scss */
      .product-section.product-section-4 .product-card {
        max-width: 250px; } }
    /* line 48, src/assets/scss/blocks/_product-section.scss */
    .product-section.product-section-4 .product-card .img-wrap {
      height: 386px; }
      @media (max-width: 1279.98px) {
        /* line 48, src/assets/scss/blocks/_product-section.scss */
        .product-section.product-section-4 .product-card .img-wrap {
          height: 290px; } }
      @media (max-width: 992.98px) {
        /* line 48, src/assets/scss/blocks/_product-section.scss */
        .product-section.product-section-4 .product-card .img-wrap {
          height: 386px; } }
      @media (max-width: 767.98px) {
        /* line 48, src/assets/scss/blocks/_product-section.scss */
        .product-section.product-section-4 .product-card .img-wrap {
          height: 320px; } }
      @media (max-width: 575.98px) {
        /* line 48, src/assets/scss/blocks/_product-section.scss */
        .product-section.product-section-4 .product-card .img-wrap {
          height: 340px; } }

/* line 1, src/assets/scss/blocks/_subscribe.scss */
.subscribe-section {
  padding: 70px 0 85px 0;
  background: url("../../assets/img/subscribe-background.svg") no-repeat center;
  background-size: cover; }
  @media (max-width: 575.98px) {
    /* line 1, src/assets/scss/blocks/_subscribe.scss */
    .subscribe-section {
      background-position: 55%;
      padding: 40px 0 55px 0;
      margin-top: 0; } }
  @media (max-width: 419px) {
    /* line 1, src/assets/scss/blocks/_subscribe.scss */
    .subscribe-section {
      background-position: 60%; } }
  /* line 13, src/assets/scss/blocks/_subscribe.scss */
  .subscribe-section form {
    max-width: 415px;
    margin-top: 39px; }
    /* line 16, src/assets/scss/blocks/_subscribe.scss */
    .subscribe-section form input, .subscribe-section form select.select-custom {
      height: 54px;
      border-radius: 2px;
      border: none; }
    @media (max-width: 575.98px) {
      /* line 13, src/assets/scss/blocks/_subscribe.scss */
      .subscribe-section form {
        max-width: 80%; } }
  /* line 26, src/assets/scss/blocks/_subscribe.scss */
  .subscribe-section .custom-checkbox p {
    font-size: 0.7rem; }
  /* line 30, src/assets/scss/blocks/_subscribe.scss */
  .subscribe-section button.blue-button {
    border-color: #0e2759;
    color: #ffffff;
    background-color: #0e2759; }
    /* line 34, src/assets/scss/blocks/_subscribe.scss */
    .subscribe-section button.blue-button:hover {
      color: #0e2759;
      background-color: #ffffff;
      border: 1px solid #0e2759; }
