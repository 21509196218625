.product-inner-swiper {
  height: 123px;

  .swiper-slide {
    img {
      width: 100%;
      object-fit: contain;
      @include media-breakpoint-down(lg){
        height: 100%;
      }
    }
  }
}

.img-placeholder {
  position: relative;
  padding-right: 9px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  line-height: 0;
  @include media-breakpoint-down(lg) {
    width: fit-content;
    margin: 0 auto 20px auto;
  }
  &:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    left: 10px;
    bottom: 0;
    background-color: rgba(14,39,89,0.1);
  }
  img {
    width: 100%;
    transition: $transition-all;
    opacity: 1;
    @include media-breakpoint-down(lg){
      max-height: 400px;
    }
    &.transitioning {
      transition: $transition-all;
      opacity: 0.3;
    }
  }
}