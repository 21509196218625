.text-page {
  padding-top: 40px;
  h2 {
    margin-bottom: 20px;
    font-weight: 400;
  }
  h3 {
    margin-bottom: 20px;
    font-family: "PlayfairDisplay";
    line-height: 1.6666em;
  }
}

.text-block {
  margin-bottom: 40px;

  p, li {
    font-size: 0.8rem;
    line-height: 1.375em;
    @include media-breakpoint-down(sm){
      font-size: 0.7rem;
    }
  }

  ol {
    list-style: decimal;
    margin-bottom: 20px;
    padding-left: 20px;
    margin-top: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}