footer {
  padding: 60px 0 28px 0;
  background-color: $dark-blue;
  * {
    color: $white;

  }
  .row {
    &:first-of-type {
      padding-bottom: 80px;
      @include media-breakpoint-down(sm){
        padding-bottom: 40px;
      }
      p {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 35px;
      }
      h6 {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 17px;
        margin-bottom: 20px;
        font-family: "Open Sans";
        opacity: 0.4;
      }
      a {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
        display: block;
      }
      & > div {
        @include xsmall-mobile {
          width: 100%!important;
          max-width: 100%!important;
          text-align: start!important;
        }
      }
    }
    &:nth-of-type(2) {
      p {
        opacity: 0.4;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 17px;
      }

      img {
        width: 129px;
      }
      & > div {
        padding-top: 22px;
        box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.05);
      }
    }
  }
}