.actions-tooltip {
  margin-left: 30px;
  .dropdown-toggle {
    height: 32px;
    width: 32px;
    background-color: rgba(14,39,89,0.1);
    border-radius: 50%;
    &:after {
      content: none;
    }
  }
  .dropdown-menu {
    margin-top: 15px!important;
    border: 1px solid rgba(14,39,89,0.2);
    padding: 5px 20px;

    &:after {
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      transform: rotate(45deg);
      top: -7px;
      right: 8px;
      background-color: $white;
      border-left: 1px solid rgba(14,39,89,0.2);
      border-top: 1px solid rgba(14,39,89,0.2);
    }
    .dropdown-item {
      font-size: 0.7rem;
      line-height: 40px;
      padding: 0;
      &:hover {
        text-decoration: underline;
        background-color: transparent;
      }

      &.active, &:focus, &:active, &:focus-visible {
        background-color: transparent;
        color: inherit;
        outline: none;
        border: none;

      }
    }

  }
}