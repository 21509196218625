.product-radios-row {
  gap: 10px;
  .product-select-radio {
    flex: 1;
  }
}

.duration-radios-column {
  flex-direction: column;
}

.ipad-frame {
  width: 90px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  line-height: 0;
  @include media-breakpoint-down(sm){
    width: 70px;
    grid-row: 1/4;
  }
  @include small-mobile {
    margin: 0;
  }
  img:first-of-type {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
  img:last-of-type {
    position: absolute;
    top: 2px;
    bottom: 4px;
    z-index: 1;
    left: 2px;
    right: 5px;
    width: 96%!important;
  }
}

.product-select-combo-wrap {
  position: relative;
  display: flex;
  align-items: baseline;
  width: 140px;
  margin: 0 auto;
  @include media-breakpoint-down(xl){
    width: 120px;
  }
  @include media-breakpoint-down(lg){
    width: 100%;
  }
  @include media-breakpoint-down(sm){
    grid-row: 1/4;
  }
  & > img {
    object-fit: contain;
    object-position: left;
  }
  .ipad-frame {
    width: 67px;
    position: absolute;
    right: 0;
    bottom: 0;

  }
  .img-wrap {
    margin-bottom: 0;
  }
}