a:hover {
  color: $accent;
}

.link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.add-more {
  font-size: 0.75rem;
  color: $blue;
}

.link-to-user-dashboard {
  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
