.card-select-wrap {
  position: relative;
  display: flex;
  align-items: center;
  input {
    display: none;
  }
  .input-wrap {
    border: $primary-border;
    border-radius: 2px;
    padding: 13px 20px 16px 20px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-image: url("../../assets/img/chevron-down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      right: 20px;
      height: 8px;
      width: 14px;
      transform: rotateX(0) translateY(-50%);
    }
    &:not(.collapsed){
      &:after {
        transform: rotateX(180deg) translateY(50%);
      }
    }
    img {
      max-width: 30px;
    }
    h6 {
      font-size: 0.7rem;
    }
    span {
      opacity: 0.4;
      font-size: 0.65rem;
    }
  }
  .card-select-options {
    display: none;
    background-color: $white;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    max-height: 160px;
    overflow-y: auto;
    flex-direction: column;
    border: $primary-border;
    border-top: none;
    z-index: 5;
    .card-radio-option-wrap {
      padding: 0 19px;
      border-bottom: $primary-border;
      &:last-of-type {
        border-bottom: none;
      }
      input {
        display: none;
      }
      label {
        cursor: pointer;
        height: 50px;
        img {
          width: 21px;
          flex-shrink: 0;
        }
        h6 {
          font-size: 0.7rem;
        }
        span {
          opacity: 0.4;
          font-size: 0.65rem;
        }
      }
    }
    &.show {
      display: flex;
    }
  }
}

.input-color-wrap {
  .select-input-wrap {
    width: 100%;
    position: relative;
    .color-display {
      position: absolute;
      border-radius: 50%;
      left: 20px;
      top: 13px;
      height: 20px;
      width: 20px;
    }

    select {
      padding-left: 50px;
    }
  }


}