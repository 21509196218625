.selection-label {
  position: relative;
  height: 22px;
  font-size: 0.5rem;
  line-height: 22px;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 3px;
  margin-left: 10px;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 3;
  &:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    top: 3px;
    right: -8px;
    border-radius: 2px;
    transform: rotate(45deg);
    z-index: -1;

  }
  &.discount-label {
    background-color: $yellow;
    &:after {
      background-color: $yellow;
    }
  }
  &.gift-label {
    background-color: $red;
    color: $white;
    &:after {
      background-color: $red;
    }
  }
  @include media-breakpoint-down(sm){
    position: absolute;
    top: 10px;
    left: -10px;
  }
}

.subscription-info {
  img {
    margin-right: 15px;
    @include media-breakpoint-down(md) {
      margin-right: 8px;
    }
  }
  span {
    font-size: 0.7rem;
    line-height: 1.756em;
  }
}

.items-in-a-cart {
  position: absolute;
  background-color: $accent;
  color: $white;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  font-size: 0.6rem;
  line-height: 19px;
  text-align: center;
  top: -10px;
  right: -10px;
}