.sidebarcart {
  flex-shrink: 0;
  width: 590px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  background-color: $white;
  overflow-y: scroll;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: $transition-all;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(lg){
    width: 500px;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &.show {
    transform: translateX(0);
  }

  .sidebarcart-top {
    border-bottom: $primary-border;
    padding: 20px 30px 20px 25px;

    h3 {
      font-size: 1.2rem;
      line-height: 35px;
      font-family: 'PlayfairDisplay';
      @include xsmall-mobile {
        font-size: 1rem;
      }
    }
  }

  .cart-item {
    padding: 25px 24px 40px 25px;
    position: relative;
    @include media-breakpoint-down(sm){
      padding: 20px 15px 30px 15px;
    }
    h5 {
      font-size: 1rem;
      line-height: 1.25em;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm){
        font-size: 0.8rem;
        margin-right: 15px;
      }
    }
    img {
      max-height: 139px;
      @include media-breakpoint-down(sm){
        max-height: 90px;
      }
    }

    h6 {
      font-family: "Open Sans";
      font-size: 0.7rem;
      font-weight: 600;
      @include media-breakpoint-down(sm){
        font-size: 0.65rem;
      }
    }

    p {
      font-size: 0.7rem;
    }

    .price {
      color: $accent;
      font-size: 0.7rem;
      font-weight: 700;
      line-height: 1.5625em;
      white-space: nowrap;
      @include small-mobile {
        position: absolute;
        bottom: 30px;
        left: 15px;
      }
    }
    .text-blue {
      font-size: 0.75rem;
      white-space: nowrap;
    }
  }

  .sidebarcart-bottom {
    margin-top: auto;
    padding: 25px;
    border-top: $primary-border;
    p {
      font-size: 0.8rem;
      line-height: 1.5625em;
      &:last-of-type {
        font-weight: 700;
      }
    }
    button {
      margin-top: 25px;
    }
  }
}