#user-nav-tabContent, #user-empty-nav-tabContent {
  padding-top: 60px;
  * {
    font-family: "Open Sans";
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 35px;
  }
  .tab-content-title {
    margin-bottom: 40px;
    .button {
      border: none;
      background-color: rgba(14,39,89,0.1);
      color: $black;
      height: 46px;
      &:hover {
        background-color: rgba(14,39,89,0.2);
      }
    }
  }
}

.user-dashboard-row {
  padding: 29px 20px 35px 0;
  border-top: $primary-border;
  @include media-breakpoint-down(sm){
    padding: 25px 0;
  }
   &:last-of-type {
     border-bottom: $primary-border;
   }

  h5 {
    font-weight: 600;
    font-size: 0.75rem;
  }
  h6 {
    font-weight: 400;
    font-size: 0.7rem;
    margin-top: 5px;
  }
  p {
    font-size: 0.7rem;
    opacity: 0.6;
    margin-top: 20px;
  }
  &.payment-row {
    padding: 33px 20px 32px 0;
  }
  &.user-settings-row {
    padding: 28px 28px 27px 0;
    @include media-breakpoint-down(sm){
      padding: 22px 0;
    }

    a {
      font-size: 0.75rem;
      line-height: 1.6666em;
      text-align: right;
      color: $blue;
      margin-left: 15px;

      img {
        margin-left: 30px;
      }
    }
  }
  &.user-order-row {
    padding: 27px 28px 28px 0;
    @include media-breakpoint-down(sm){
      padding: 22px 0px 22px 0;
    }
    .order-title {
      flex: 1;
    }
    .order-info-elems {
      flex: 1;
    }
    ul {
      margin-top: 5px;
      li {
        font-size: 0.7rem;
        line-height: 1.43em;
        @include media-breakpoint-down(sm){
          font-size: 0.60rem;
        }
      }
    }
    .date {
      opacity: 0.6;
      font-size: 0.7rem;
      line-height: 1.666em;
      white-space: nowrap;
      padding: 0 15px;
      @include media-breakpoint-down(sm){
        font-size: 0.60rem;
      }
    }

    .price {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.666em;
      margin-right: 60px;
      white-space: nowrap;
      @include media-breakpoint-down(sm){
        margin-right: 20px;
        font-size: 0.60rem;
      }
    }
  }
}

.user-info-row {
  max-width: 525px;
  //margin-top: 60px;
  img {
    height: 68px;
    width: 68px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid $white;
    object-fit: cover;
    object-position: center;
    margin-right: 34px;
  }
  h5 {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.375em;
    margin-bottom: 10px;
  }
  p {
    font-size: 0.7rem;
  }
}

.subscriptions-row {
  max-width: 625px;
  margin-top: 40px;
   & > .d-flex {
      gap: 20px;
   }
}