.container-md {
  @include media-breakpoint-up(xl){
    max-width: 1030px;
  }
}

.about-text-block {
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    line-height: 1.375em;
    font-size: 0.8rem;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm){
      font-size: 0.7rem;
      margin-bottom: 20px;
    }
  }
}