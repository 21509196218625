.modal {
  z-index: 20;
  p {
    font-size: 0.8rem;
    line-height: 1.375em;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm){
      font-size: 0.7rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .modal-dialog {
    max-width: 996px;
    @include media-breakpoint-down(xl){
      max-width: 800px;
    }
    @include media-breakpoint-down(lg){
      max-width: 700px;
    }
    @include media-breakpoint-down(md){
      max-width: 550px;
    }
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 3;
  }
  .modal-body {
    padding: 0;

    .modal-right {
      flex: 1;
      padding: 60px 60px 70px 60px;
      background-color: $backgroundColor;
      @include media-breakpoint-down(xl){
        padding: 40px 40px 55px 40px;
      }
      @include media-breakpoint-down(md){
        padding: 30px 20px 35px 20px;
      }
      @include media-breakpoint-down(sm){
        padding: 20px 20px 20px 20px;
      }
      .button {
        height: 54px;
        @include media-breakpoint-down(sm){
          height: 46px;
          font-size: 0.7rem;
        }
      }
    }

    .modal-left {
      padding: 60px 60px 70px 60px;
      background-color: $white;
      flex: 1;
      @include media-breakpoint-down(xl){
        padding: 40px 40px 55px 40px;
      }
      @include media-breakpoint-down(md){
        padding: 30px 20px 35px 20px;
      }
      @include media-breakpoint-down(sm){
        padding: 20px 20px 20px 20px;
      }
      .button {
        @include media-breakpoint-down(md){
          font-size: 0.7rem;
          img {
            left: 15px;
            max-width: 20px;
          }
        }
        @include media-breakpoint-down(sm){
          height: 46px;

          img {
            left: 20px;
            max-width: 30px;
          }
        }
      }
    }
  }
  .modal-title {
    font-size: 1.8rem;
    line-height: 1.278em;
    margin-bottom: 30px;
    @include media-breakpoint-down(md){
      font-size: 1.2rem;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 20px;
    }
  }

  &.side-modal {
    transform: translateX(110%);
    transition: $transition-all-slow;

    h3 {
      padding-right: 50px;
      font-family: 'PlayfairDisplay';
    }

    .modal-dialog {
      margin: 0 0 0 auto;
      min-height: 100vh;
      max-width: 795px;
    }
    .modal-body {
      min-height: 100vh;
    }
    .modal-content {
      height: 100%;
      border-radius: 0;
      border: none;
    }
    &.show {
      transform: translate(0);
    }
    .modal-body {
      padding: 25px 25px 45px 25px;
    }
    textarea {
      height: 110px;
    }
    .button {
      height: 46px;
      border-radius: 2px;
      font-size: 0.75rem;
    }
  }
  &.modal-small {
    .modal-dialog {
      max-width: 470px;
    }

  }
  &.payment-modal {
    .modal-dialog {
      max-width: 588px;

    }
    .modal-content {
      border-radius: 0;
      border: none;
    }
    .modal-body {
      padding: 70px 100px 80px 100px;
      text-align: center;
      @include media-breakpoint-down(sm){
        padding: 50px 40px 60px 40px;
      }
      .modal-title {
        margin-top: 50px;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 60px;
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.6;
}