//remove default styling
button {
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;

  img {
    object-position: center;
  }
  &:focus-visible, &:focus-within, &:active, &:focus {
    outline: none;
  }
}

.button {
  height: 54px;
  color: $blue;
  background-color: $white;
  font-size: 0.8rem;
  letter-spacing: 0;
  line-height: 1.333em;
  border: $primary-border;
  border-radius: 2px;
  transition: $transition-all;
  @extend .absolute-center;
  &:hover {
    border-color: $blue;
    color: $white;
    background-color: $blue;
  }
  &.blue-button {
    border-color: $blue;
    color: $white;
    background-color: $blue;
    &:hover {
      color: $blue;
      background-color: transparent;
      border: $primary-border;
    }
  }

  &.grey-ish-button {
    background-color: rgba(14,39,89,0.1);
    border-color: transparent;
    color: $black;
    &:hover {
      color: $blue;
      border-color: rgba(14,39,89,0.2);
    }
  }
  &.grey-button {
    background-color: $lightest-sheer;
    border-color: transparent;
    color: $black;
    &:hover {
      color: $blue;
      border-color: rgba(14,39,89,0.2);
    }
  }

  &.google-button {
    background-color: $google-red;
    color: $white;
    border-color: $google-red;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;

    }
    &:hover {
      background-color: darken($google-red, 10%);
    }
  }
}

.hover-for-links {
  transition: color 0.3s ease-in-out;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    //background-color: $blue;
    transform: scaleX(0);
    transition: transform 0.4s ease-in-out;
    transform-origin: right;
  }
  &:hover, &:focus {
    //color: $blue;
    text-decoration: none;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.social-buttons-separator {
  margin: 15px 0;
  div {
    width: 100%;
    flex-grow: 2;
    height: 1px;
    background-color: rgba(26,26,26,0.15);
  }
  span {
    font-weight: 400;
    flex-grow: 1;
    white-space: nowrap;
    text-transform: uppercase;
    color: rgba(0,0,0,0.4);
    font-size: 0.6rem;
    padding: 0 20px;
    @include media-breakpoint-down(sm){
      padding: 0 10px;
    }
  }
}

.log-out-button {
  font-size: 0.75rem;
  color: $black;
  padding: 17px 0;
  color: $red;
  @include media-breakpoint-down(lg){
    height: 75px;
  }
  @include media-breakpoint-down(sm){
    height: 55px;
  }
}


