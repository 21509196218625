@mixin transform($v, $d1, $d2 : null) {
  @if $v == translateX {
    @include translateX($d1);
  } @else if $v == rotate {
    @include rotate($d1);
  } @else if $v == skewX {
    @include skewX($d1);
  } @else if $v == skew {
    @include skew($d1);
  } @else if $v == translateY {
    @include translateY($d1);
  } @else if $v == translate {
    @include translate($d1, $d2);
  }
}

@mixin translate($v1, $v2) {
  transform: translate($v1, $v2);
  -webkit-transform: translate($v1, $v2);
}

@mixin translateX($v1) {
  transform: translateX($v1);
  -webkit-transform: translateX($v1);
}

@mixin translateY($v1) {
  transform: translateY($v1);
  -webkit-transform: translateY($v1);
}

@mixin rotate($v1) {
  transform: rotate($v1);
  -webkit-transform: rotate($v1);
}

@mixin skewX($v1) {
  transform: skewX($v1);
  -webkit-transform: skewX($v1);
}

@mixin skew($v1) {
  transform: skew($v1);
  -webkit-transform: skew($v1);
}

@mixin transition-duration($v1) {
  -webkit-transition-duration: $v1;
  transition-duration: $v1;
}

@mixin after($left, $top, $width, $height) {
  @content;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: $left;
    top: $top;
    width: $width;
    height: $height;
  }
}

@mixin media-query($val...) {
  @if length($val) == 1 {
    @media (min-width: nth($val, 1)) {
      @content
    }
  }
  @if length($val) == 2 {
    @media (min-width: nth($val, 1)) and (max-width: nth($val, 2)) {
      @content
    }
  }
}


@mixin mobile {
  @media (max-width: 575px) {
    @content
  }
}

@mixin tablet-portrait {
  @media (max-width: 767px) {
    @content
  }
}

@mixin tablet-landscape {
  @media (max-width: 991px) {
    @content
  }
}

@mixin tablet-landscape-from {
  @media (min-width: 991px) {
    @content
  }
}

@mixin desktop {
  @media (max-width: 1199px) {
    @content
  }
}

@mixin md-mobile {
  @media (max-width: 500px) {
    @content
  }
}

@mixin small-mobile {
  @media (max-width: 419px) {
    @content
  }
}

@mixin xsmall-mobile {
  @media (max-width: 360px) {
    @content
  }
}
