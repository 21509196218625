body {
  background-color: $backgroundColor;

  &.no-scroll {
    overflow: hidden;
    height: 100vh;
  }

  &.filter {
    .black-filter {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 13;
    }
  }
}

html {
  font-size: 20px;
  scroll-behavior: smooth;
}

* {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

img {
  height: auto;
}

a, button {
  transition: .3s;

}

a {
  color: inherit;


}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: initial;
}

.transition {
  transition: .3s;
}
