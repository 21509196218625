.op-60 {
  opacity: 0.6;
}

.op-40 {
  opacity: 0.4;
}

.absolute-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.absolute-center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-border-bottom {
  @include media-breakpoint-down(md) {
    border-bottom: $primary-border;
  }
}

.w-fit {
  width: fit-content;
}

.blured {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(51,51,51,0.6);
    backdrop-filter: blur(3px);
  }

}

.font-weight-semi {
  font-weight: 600;
}

.mobile-visible-only {
  @include media-breakpoint-up(sm){
    display: none!important;
  }
}

.hidden {
  display: none;
}

.card-border-radius {
  border-radius: $card-border-radius;
}

.border-bottom {
  border-bottom: $primary-border;
}

.row-wrap-sm {
  @include media-breakpoint-down(sm){
    margin-right: -10px;
    margin-left: -10px;
  }

}