@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../assets/fonts/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
}


@font-face {
  font-family: "PlayfairDisplay";
  src: url("../../assets/fonts/PlayfairDisplay-Black.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "CabinetGrotesk";
  src: url("../../assets/fonts/CabinetGrotesk-Regular.otf") format("opentype");
  font-weight: 400;
}


@font-face {
  font-family: "CabinetGrotesk";
  src: url("../../assets/fonts/CabinetGrotesk-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("../../assets/fonts/MontserratAlternates-Italic.ttf") format("truetype");
  //font-weight: 400;
  font-style: italic;
}



