.not-found-section {
  position: relative;
  min-height: calc(100vh - 168px);
  display: flex;
  align-items: center;
  padding-top: 50px;
  @include media-breakpoint-down(lg){
    min-height: calc(100vh - 178px);
  }
  @include media-breakpoint-down(sm){
    padding-bottom: 250px;
  }
  p {
    font-size: 0.8rem;
    margin-top: 20px;
    text-align: center;
  }
  button {
    margin-top: 80px;
    width: 66%;
    @include media-breakpoint-down(md){
      width: 80%;
    }
    @include media-breakpoint-down(sm){
      margin-top: 40px;
    }
  }
  img {
    position: absolute;
    right: 66%;
    bottom: 0;
    height: 100%;
    z-index: -1;
    object-fit: contain;
    max-height: 90%;
    object-position: bottom;

    @include media-breakpoint-down(md){
      height: 70%;
    }
    @include media-breakpoint-down(sm){
      max-height: 250px;
      bottom: 0;
      right: 55%;
      transform: translateX(50%);
    }
  }
}