.payment-card-form {
  & > a {
    float: right;
    display: inline-block;
    width: 50%;
  }

  & > select {
    float: left;
    display: inline-block;
    width: 50%;
  }
}

#discount-input-accordion {
  border: $primary-border;
  border-radius: 2px;
  .discount-input-button {
    height: 46px;
    padding: 0 20px;
    img {
      transition: $transition-all;
    }
    span {
      font-size: 0.75rem;
    }
    &:not(.collapsed){
      img:nth-of-type(2) {
        transform: rotate(90deg);
      }
    }
  }
  .input-wrap {
    border-radius: 0 0 2px 2px;
    padding-right: 20px;
    border-top: $primary-border;
    input {
      border: none;
      padding-left: 20px;
      width: 100%;
      background-color: transparent!important;
      &::placeholder {
        color: $black;
        opacity: 1;
      }
    }

    button {
      background-color: $black;
      color: $white;
      text-transform: uppercase;
      height: 28px;
      padding: 0 10px;
      border-radius: 2px;
      font-size: 0.6rem;
    }
  }
}