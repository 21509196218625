form {

}

.input-wrap {
  width: 100%;

  & > label {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.37em;
    margin-bottom: 10px;
  }
}

input, textarea {
  width: 100%;
  border: $primary-border;
  border-radius: 2px;
  height: 46px;
  font-size: 0.75rem;
  line-height: 1.333em;
  padding: 0 20px;
  background-color: $white;
  @include media-breakpoint-down(sm){
    padding: 0 15px;
  }

  ::placeholder {
    opacity: 0.6;
  }
  &:focus, &:focus-visible, &:focus-within, &:active, &:placeholder-shown {
    outline: none;

  }
  &:read-only {
    background-color: $backgroundColor;
    border: none;
  }
}

textarea {
  resize: none;
  padding: 12px 20px;
}

select.select-custom {
  @extend input;
  background-color: $white;
  background-image: url("../../assets/img/chevron-down.svg");
  color: $blue;
  option {
    color: $blue;
  }
  &:read-only {
    background-color: $white;
    border: $primary-border;
  }
  &:disabled {
    opacity: 0.6;
    background-color: rgba(0,0,0,0.05);
    color: $black;
  }
}

form.auth-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .d-flex {
    gap: 10px;
  }
  .link {
    font-size: 0.75rem;

  }
}

.photo-upload-wrap {

  @include media-breakpoint-down(lg){

  }
  label {
    margin-bottom: 0;
    & ~ p {
      font-size: 0.6rem;
      line-height: 1.666em;
      margin-bottom: 10px;
    }
  }
}
.custom-photo-upload {
  width: 50%;
  border: $primary-border;
  height: 46px;
  padding: 0 20px;
  @include media-breakpoint-down(sm){
    width: 100%;
  }
  .uploader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: $blue;
      font-size: 0.75rem;
    }
  }

  input {
    display: none;
  }
}