.subscribe-section {
  padding: 70px 0 85px 0;
  background: url("../../assets/img/subscribe-background.svg") no-repeat center;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    background-position: 55%;
    padding: 40px 0 55px 0;
    margin-top: 0;
  }
  @include small-mobile {
    background-position: 60%;
  }
  form {
    max-width: 415px;
    margin-top: 39px;
    input {
      height: 54px;
      border-radius: 2px;
      border: none;
    }
    @include media-breakpoint-down(sm) {
      max-width: 80%;
    }
  }
  .custom-checkbox {
    p {
      font-size: 0.7rem;
    }
  }
  button.blue-button {
    border-color: $blue;
    color: $white;
    background-color: $blue;
    &:hover {
      color: $blue;
      background-color: $white;
      border: 1px solid $blue;
    }
  }
}